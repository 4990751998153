import * as React from 'react';
import styled from 'styled-components';
import { colorsV2 } from 'style/colors-v2';
import { Typography } from 'components/DesignSystemV2/Typography';
import { LazyImage } from 'components/LazyImage';
import IconTeacherOverviewGraduateHatSVG from './icons/IconTeacherOverviewGraduateHat';
import IconQuote from './icons/IconQuote';
import Marquee from 'react-fast-marquee';
import { motion, AnimatePresence } from 'framer-motion';

// Dummy data
import ArrowButton from 'components/ArrowButton';
import { toScreen } from 'utils/media-query/responsive.util';

const images = [
  'https://media.graphassets.com/RAOc0WRbQu6Xkj0wsTKn',
  'https://media.graphassets.com/v311IhzyQpy4PHiNHF6A',
  'https://media.graphassets.com/rjBg1jIR4Og50aYk9C2x',
  'https://media.graphassets.com/n3V5ulFESFuaPGg9zj1U',
  'https://media.graphassets.com/bjylswQcT4OZMieVP1Au',
  'https://media.graphassets.com/ujw2F6XvT0eeGeVsPbiC',
  'https://media.graphassets.com/eV2peo7qR4WkK5dhXTdX',
  'https://media.graphassets.com/n3V5ulFESFuaPGg9zj1U'
];

const quotes = [
  {
    quote:
      'Tôi thắc mắc vì sao học sinh Phổ thông Năng khiếu chuộng học IELTS tại Đình Lực. Tìm hiểu sâu, tôi nhận thấy Linearthinking giúp học sinh giao tiếp, viết tốt hơn và tự tin hơn khi dùng tiếng Anh. Học sinh tôi thi IELTS luôn đạt điểm cao.',
    profile: {
      name: 'GS.TS Nguyễn Thị Thanh Mai',
      roles: [
        'Hiệu trưởng Trường Phổ thông Năng khiếu',
        'PGĐ Đại học Quốc gia TP.HCM'
      ],
      image:
        'https://ap-northeast-1.graphassets.com/A6FEvrzzmSM6yrs3MAVEqz/cm8fpgfv81enk0719urv62ma1'
    }
  },
  {
    quote:
      'Linearthinking là bước tiến trong dạy và học tiếng Anh, đặc biệt là tiếng Anh học thuật, giúp người học không chỉ nắm vững ngôn ngữ mà còn phát triển tư duy phản biện và hệ thống, yếu tố cốt lõi để thành công trong môi trường giáo dục quốc tế',
    profile: {
      name: 'TS. Đỗ Hữu Nguyên Lộc',
      roles: [
        'Chủ tịch Hội TESOL TP.HCM',
        'Phó Hiệu trưởng Trường Đại học Kinh tế Tài chính TP.HCM'
      ],
      image:
        'https://ap-northeast-1.graphassets.com/A6FEvrzzmSM6yrs3MAVEqz/cm8fqpuii25i80713qf9jtmin'
    }
  },
  {
    quote:
      'Báo Giáo dục & Thời đại mong muốn giới thiệu những phương pháp giảng dạy hiệu quả, và Linearthinking chính là cách tiếp cận mới giúp thầy cô cũng như là học trò có thể tương tác và có những tiết học thú vị',
    profile: {
      name: 'Nhà báo Dương Thanh Hương',
      roles: ['Phó Tổng biên tập Báo Giáo dục & Thời Đại'],
      image:
        'https://ap-northeast-1.graphassets.com/A6FEvrzzmSM6yrs3MAVEqz/cm8fpladq1yis0717nk43jehs'
    }
  },
  {
    quote:
      'Linearthinking tại DOL không chỉ là phương pháp học tiếng Anh, mà là công cụ nâng tầm tư duy - giúp học viên chinh phục IELTS logic, mạch lạc và ứng dụng mạnh mẽ vào học thuật, công việc.',
    profile: {
      name: 'ThS. Phạm Công Nhật',
      roles: [
        'Thạc sĩ Quản lý Truyền thông Anh Quốc',
        'Giảng viên Viện quốc tế Trường Đại học Kinh tế Tài chính TP.HCM'
      ],
      image:
        'https://ap-northeast-1.graphassets.com/A6FEvrzzmSM6yrs3MAVEqz/cm8fqsfb524in0717xnq48jym'
    }
  },
  {
    quote:
      'Là một người làm tâm lý, mình tin vào sức mạnh của tư duy và đó cũng là lý do Linearthinking thuyết phục mình. Không chỉ giúp học tiếng Anh, phương pháp này còn rèn tư duy logic, phản biện, thứ vũ khí cần thiết trong thời đại AI bùng nổ',
    profile: {
      name: 'TS. Đào Lê Hoà An',
      roles: ['Giảng viên Tâm lý học Trường Đại học Mở TP.HCM'],
      image:
        'https://ap-northeast-1.graphassets.com/A6FEvrzzmSM6yrs3MAVEqz/cm8fqx7jk26lh071778yyhayh'
    }
  }
].map((quote, index) => ({
  ...quote,
  listIndex: index
}));

const TOTAL_QUOTES = quotes.length;
const VISIBLE_QUOTES = 3;

const Main = styled.div`
  display: grid;
  grid-template-columns: 548px auto;
  grid-template-rows: auto;
  align-items: center;
  gap: 48px;

  ${toScreen(1143)} {
    grid-template-columns: repeat(1, 1fr);
    gap: 64px;
  }
`;

const MarqueeWrapper = styled(Marquee)`
  margin-bottom: 100px;
  /* Leave space for rotation */
  padding: 20px 0;

  ${toScreen(775)} {
    margin-bottom: 64px;
  }

  img {
    width: 176px;
    height: auto;
  }

  .rfm-child {
    border-radius: 24px;
    border: 2px solid ${colorsV2.white100};
    margin-right: 16px;
    box-shadow: 0 1px 3px 0 ${colorsV2.blackShadow7};

    &:nth-child(odd) {
      transform: rotate(4deg);
    }

    &:nth-child(even) {
      transform: rotate(-4deg);
    }
  }
`;

const Left = styled.div`
  display: grid;
  gap: 24px;
  height: fit-content;
`;
const Info = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 12px;
`;
const Right = styled.div`
  display: grid;
  gap: 24px;
`;
const CardWrapper = styled.div`
  position: relative;
  aspect-ratio: 500 / 460;

  ${toScreen(1143)} {
    aspect-ratio: 900 / 460;
  }

  ${toScreen(775)} {
    aspect-ratio: 410 / 460;
  }

  ${toScreen(457)} {
    aspect-ratio: 370 / 460;
  }
`;
const Card = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 24px 32px;
  border-radius: 24px;
  z-index: -1;
  display: flex;
  flex-direction: column;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  transform-origin: center;

  &.active {
    z-index: 10;
  }
`;
const QuoteWrapper = styled.div`
  margin-bottom: auto;
`;

const Quote = styled(Typography)`
  position: relative;
`;

const OpenQuote = styled(IconQuote)`
  position: absolute;
  left: 14px;
  top: 28px;
`;

const CloseQuote = styled(IconQuote)`
  transform: translateY(-6px) rotateZ(180deg);
  margin-left: 4px;
`;

const ProfileContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 70px 1fr;
  align-items: center;
  gap: 16px;

  .profile-image {
    width: 60px;
    height: 80px;
    border: 2px solid white;
    border-radius: 12px;
    overflow: hidden;
    transform: rotate(-12deg);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
const ProfileInfo = styled.div`
  display: grid;
  gap: 4px;
`;

const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 40px 40px;
  gap: 12px;
  justify-content: center;
  align-items: end;
  margin-top: 24px;
`;

const RoleList = styled.ul`
  list-style: ${props => (props.$hideListStyle ? 'none' : 'disc')};
  list-style-position: inside;
  padding: 0;
  margin: 0;
`;

const RoleItem = styled.li`
  margin-bottom: 4px;
  &::marker {
    color: ${colorsV2.neutral120};
  }
`;

const Role = styled(Typography)`
  white-space: pre-line;
  position: relative;
  left: ${props => (props.$hideListStyle ? '0' : '-8px')};
`;

const TalkAboutLinearthinking = () => {
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const visibleQuotes = React.useMemo(() => {
    const result = [];

    // Add current card and up to 2 more after it
    for (let index = 0; index < VISIBLE_QUOTES; index++) {
      const nextQuote = quotes[(currentIndex + index) % TOTAL_QUOTES];
      result.push({
        ...nextQuote,
        position: index // 0 = front, 1 = middle, 2 = back
      });
    }
    return result;
  }, [currentIndex]);

  function getRotation(position) {
    if (position % VISIBLE_QUOTES === 1) return 5;
    if (position % VISIBLE_QUOTES === 2) return -5;
    return 0;
  }

  function getBackgroundColor(index) {
    switch (index) {
      case 1:
        return colorsV2.green10;
      case 2:
        return colorsV2.yellow10;
      case 3:
        return colorsV2.primary10;
      case 4:
        return colorsV2.purple10;
      default:
        return colorsV2.blue10;
    }
  }

  function getBorderColor(index) {
    switch (index) {
      case 1:
        return colorsV2.green40;
      case 2:
        return colorsV2.yellow40;
      case 3:
        return colorsV2.primary40;
      case 4:
        return colorsV2.purple40;
      default:
        return colorsV2.blue40;
    }
  }

  function getZIndex(index) {
    return VISIBLE_QUOTES - index;
  }

  function handleArrowClick(direction) {
    if (direction === 'left') {
      setCurrentIndex(
        prevIndex => (prevIndex - 1 + TOTAL_QUOTES) % TOTAL_QUOTES
      );
    } else {
      setCurrentIndex(prevIndex => (prevIndex + 1) % TOTAL_QUOTES);
    }
  }

  return (
    <div>
      <MarqueeWrapper pauseOnHover={true}>
        {/* Make sure the number of images is even to avoid uneven rotation
        between the last and first image*/}
        {images.map((image, index) => (
          <LazyImage key={index} src={image} alt="news" />
        ))}
      </MarqueeWrapper>
      <Main>
        <Left>
          <Typography
            variant="bold/24-32"
            tabletVariant="bold/32-40"
            desktopVariant="bold/40-48"
            color={colorsV2.black100}
            v3
          >
            Giới học thuật nói gì về Linearthinking
          </Typography>
          <Info>
            <IconTeacherOverviewGraduateHatSVG
              color={colorsV2.neutral100}
              width={24}
              height={24}
            />
            <Typography variant="regular/16-24" color={colorsV2.neutral180}>
              Các giáo viên và nhà nghiên cứu khuyến khích việc áp dụng phương
              pháp này trong giảng dạy tiếng Anh, đặc biệt là cho người mới bắt
              đầu.
            </Typography>
          </Info>
        </Left>
        <Right>
          <CardWrapper>
            <AnimatePresence initial={false}>
              {visibleQuotes.map(item => (
                <Card
                  key={item.listIndex}
                  initial={{
                    opacity: 0,
                    y: 20,
                    rotateZ: getRotation(item.listIndex),
                    zIndex: getZIndex(item.listIndex)
                  }}
                  animate={{
                    opacity: 1 - item.position * 0.2,
                    y: item.position * 10,
                    rotateZ: getRotation(item.listIndex),
                    zIndex: getZIndex(item.position)
                  }}
                  exit={{
                    opacity: 0,
                    x: -100,
                    transition: { duration: 0.2 }
                  }}
                  transition={{
                    duration: 0.3
                  }}
                  style={{
                    backgroundColor: getBackgroundColor(item.listIndex),
                    borderColor: getBorderColor(item.listIndex)
                  }}
                >
                  <QuoteWrapper>
                    <OpenQuote color={colorsV2.neutral80} />
                    <Quote
                      variant="medium/24-32"
                      color={colorsV2.neutral140}
                      type="span"
                    >
                      {item.quote}
                    </Quote>
                    <CloseQuote color={colorsV2.neutral80} />
                  </QuoteWrapper>
                  <ProfileContainer>
                    <LazyImage
                      className="profile-image"
                      src={item.profile.image}
                      alt={item.profile.name}
                    />
                    <ProfileInfo>
                      <Typography
                        variant="semi-bold/16-24"
                        color={colorsV2.neutral180}
                      >
                        {item.profile.name}
                      </Typography>
                      <RoleList
                        $hideListStyle={item.profile.roles.length === 1}
                      >
                        {item.profile.roles.map((role, index) => (
                          <RoleItem key={index}>
                            <Role
                              $hideListStyle={item.profile.roles.length === 1}
                              variant="medium/14-20"
                              color={colorsV2.neutral120}
                              type="span"
                            >
                              {role}
                            </Role>
                          </RoleItem>
                        ))}
                      </RoleList>
                    </ProfileInfo>
                  </ProfileContainer>
                </Card>
              ))}
            </AnimatePresence>
          </CardWrapper>
          <ButtonWrapper>
            <ArrowButton
              direction="left"
              onClick={() => handleArrowClick('left')}
            />
            <ArrowButton
              direction="right"
              onClick={() => handleArrowClick('right')}
            />
          </ButtonWrapper>
        </Right>
      </Main>
    </div>
  );
};

export default TalkAboutLinearthinking;
