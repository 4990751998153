import * as React from 'react';
import styled from 'styled-components';
import { colorsV2 } from 'style/colors-v2';
import { Typography } from 'components/DesignSystemV2/Typography';
import { LazyImage } from 'components/LazyImage';
import cl from 'classnames';
import { VideoThumbnail } from 'components/VideoThumbnail';
import { toScreen } from 'utils/media-query/responsive.util';

const DATA_LIST = [
  {
    title: 'Các cách học truyền thống',
    isLinearthinking: false,
    iconImage:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/e2339d53-eb09-47a2-954e-006cc7e3f918.png',
    iconImageAlt: 'linearthinking-disadvantages',
    items: [
      'Học thuộc lòng máy móc',
      'Lâu giỏi, khó ứng dụng',
      'Là mẹo, tips, giải quyết cục bộ, chỉ ở 1 kỹ năng, hay 1 trình độ'
    ]
  },
  {
    title: 'Hệ phương pháp linearthinking',
    isLinearthinking: true,
    iconImage:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/99415db5-531f-4245-9648-3b315de8a9aa.png',
    iconImageAlt: 'linearthinking-advantages',
    items: [
      'Học theo logic & bản chất',
      'Nhanh giỏi hơn, ứng dụng tốt ',
      'Giải quyết vấn đề cho cả 4 kỹ năng và tất cả trình độ học viên'
    ]
  }
];

const Main = styled.div``;
const Left = styled.div`
  max-width: 500px;
  margin-bottom: 48px;
`;

const SubHeadline = styled(Typography)`
  margin-bottom: 16px;
`;

const Right = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 48px;

  ${toScreen(775)} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const VideoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 64px;
`;

const List = styled.div`
  display: grid;
  gap: 24px;
`;
const Card = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  gap: 20px;
  background: ${colorsV2.neutral10};
  padding: 40px 24px;
  border: 2px solid ${colorsV2.neutral80};
  border-radius: 16px;
  box-shadow: 1px 2px 0px 0px ${colorsV2.neutral80};

  .title-image {
    width: 120px;
    margin: auto;
  }
  &.linearthinking {
    background: ${colorsV2.primary10};
    border: 2px solid ${colorsV2.primary100};
    box-shadow: 1px 2px 0px 0px ${colorsV2.primary100};
  }
`;
const Item = styled.div`
  display: grid;
  grid-template-columns: 24px auto;
  gap: 12px;
  align-items: center;

  padding: 24px;
  border: 2px solid ${colorsV2.neutral80};
  border-radius: 16px;
  box-shadow: 1px 2px 0px 0px ${colorsV2.neutral80};
`;

const LinearthinkingDifferences = () => {
  return (
    <Main>
      <Left>
        <SubHeadline variant="medium/16-24" color={colorsV2.gray120}>
          Vì sao Linearthinking vượt trội?
        </SubHeadline>
        <Typography
          variant="bold/24-32"
          tabletVariant="bold/32-40"
          desktopVariant="bold/40-48"
          color={colorsV2.black100}
          v3
        >
          Sự khác biệt và tư duy khoa học đằng sau
        </Typography>
      </Left>
      <Right>
        {DATA_LIST.map((eachData, idx) => (
          <List key={idx}>
            <Card
              className={cl({
                linearthinking: eachData.isLinearthinking
              })}
            >
              <Typography
                variant="semi-bold/18-24"
                color={
                  eachData.isLinearthinking
                    ? colorsV2.primary100
                    : colorsV2.neutral180
                }
              >
                {eachData.title}
              </Typography>
            </Card>
            {eachData.items.map((eachItem, itemIndex) => (
              <Item key={itemIndex}>
                <LazyImage
                  src={eachData.iconImage}
                  alt={eachData.iconImageAlt}
                />
                <Typography
                  variant="semi-bold/16-24"
                  color={colorsV2.neutral180}
                >
                  {eachItem}
                </Typography>
              </Item>
            ))}
          </List>
        ))}
      </Right>
      <VideoWrapper>
        <VideoThumbnail
          width={712}
          caption="Cô Quỳnh, IELTS 9.0+ giám đốc học thuật tại DOL giới thiệu về Linearthinking"
          playInline
          plyrSource={{
            type: 'video',
            sources: [
              {
                src: 'kweCuKNnTXA',
                provider: 'youtube'
              }
            ]
          }}
          plyrOptions={{
            controls: ['play-large']
          }}
        />
      </VideoWrapper>
    </Main>
  );
};

export default LinearthinkingDifferences;
