import React from 'react';
import styled from 'styled-components';
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import { LazyImage } from 'components/LazyImage';

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const AvatarWrapper = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  position: relative;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const UserProfile = ({ name, school, avatar, className }) => {
  return (
    <ProfileWrapper className={className}>
      <AvatarWrapper>
        <LazyImage src={avatar} alt={name} />
      </AvatarWrapper>
      <UserInfo>
        <Typography variant="semi-bold/18-24" color={colorsV2.neutral180}>
          {name}
        </Typography>
        <Typography variant="regular/16-20" color={colorsV2.neutral120}>
          {school}
        </Typography>
      </UserInfo>
    </ProfileWrapper>
  );
};

export default UserProfile;
