import * as React from 'react';

export const IconChervonRight = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 6.41422L9.4143 5L16.4853 12.0711L9.4143 19.1422L8 17.7279L13.6569 12.0711L8 6.41422Z"
      fill="white"
    />
  </svg>
);
