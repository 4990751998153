import * as React from 'react';

/* eslint-disable react/prop-types */
import LinearthinkingDifferences from './LinearthinkingDifferences';
import LinearthinkingApplyToSkills from './LinearthinkingApplyToSkills';

const LinearthinkingDifferencesAndApplyToSkillsBase = () => {
  return (
    <div>
      <LinearthinkingDifferences />
      <LinearthinkingApplyToSkills />
    </div>
  );
};

export default LinearthinkingDifferencesAndApplyToSkillsBase;
