import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import LinearThinkingSharingList from './LinearThinkingSharingList';
import { LazyImage } from 'components/LazyImage';
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import { toScreen } from 'utils/media-query/responsive.util';
import { PAGE_SIZE, firstPageData, randomData } from './data';

const Wrapper = styled.div`
  display: grid;
  gap: 48px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;

  ${toScreen(1143)} {
    flex-direction: column;
    gap: 12px;
    align-items: start;
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 50%;

  ${toScreen(1143)} {
    max-width: 70%;
  }

  ${toScreen(775)} {
    max-width: 100%;
  }
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 390px;
`;

const LinearThinkingSharing = () => {
  const [page, setPage] = useState(0);
  const shuffledData = useMemo(() => {
    return [...firstPageData, ...randomData.sort(() => Math.random() - 0.5)];
  }, []);

  const data = useMemo(() => {
    const paginatedData = shuffledData.slice(0, (page + 1) * PAGE_SIZE);
    return {
      content: paginatedData
    };
  }, [page, shuffledData]);

  const onLoadMoreClick = () => {
    setPage(page => page + 1);
  };

  return (
    <Wrapper>
      <Header>
        <Left>
          <LazyImage
            src="https://media.graphassets.com/MnVvtDSTXB4gsGDFTTEw"
            alt="Trophy"
          />
          <Typography
            variant="bold/24-32"
            tabletVariant="bold/32-40"
            desktopVariant="bold/40-48"
            color={colorsV2.black100}
            v3
          >
            Linearthinking được mời đi chia sẻ tại
          </Typography>
        </Left>
        <Right>
          <Typography variant="medium/24-32" color={colorsV2.neutral200}>
            1000+ buổi chia sẻ
          </Typography>
          <Typography variant="regular/16-24" color={colorsV2.neutral180}>
            Được các trường Đại học & Trung học mời tham dự
          </Typography>
        </Right>
      </Header>
      <LinearThinkingSharingList
        sharingEvents={data}
        onLoadMoreClick={onLoadMoreClick}
        hasMore={page < Math.ceil(shuffledData.length / PAGE_SIZE) - 1}
      />
    </Wrapper>
  );
};

export default LinearThinkingSharing;
