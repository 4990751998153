import * as React from 'react';

function IconCheck(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM16.6746 10.5628C17.1085 10.1289 17.1085 9.42537 16.6746 8.99145C16.2406 8.55754 15.5371 8.55754 15.1032 8.99145L10.8889 13.2058L8.89679 11.2137C8.46287 10.7798 7.75935 10.7798 7.32544 11.2137C6.89152 11.6476 6.89152 12.3511 7.32544 12.785L10.1032 15.5628C10.5371 15.9967 11.2406 15.9967 11.6746 15.5628L16.6746 10.5628Z"
        fill="currentColor"
      />
    </svg>
  );
}

const IconCheck2 = React.memo(IconCheck);
export default IconCheck2;
