import * as React from 'react';

function IconLearningWritingSVG(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3045 2.81789C14.988 2.13323 16.096 2.13439 16.7793 2.81773L18.1823 4.22073C18.8662 4.90462 18.8662 6.0125 18.1823 6.69639L16.4322 8.44656C16.422 8.45808 16.4114 8.46936 16.4003 8.48039C16.3893 8.49142 16.378 8.50203 16.3665 8.51222L7.34833 17.5304C7.20768 17.671 7.01691 17.7501 6.818 17.7501H4C3.58579 17.7501 3.25 17.4143 3.25 17.0001V14.1821C3.25 13.9831 3.32903 13.7924 3.46969 13.6517L12.482 4.64019C12.494 4.62636 12.5065 4.61286 12.5197 4.59973C12.5328 4.58658 12.5463 4.57402 12.5602 4.56205L14.3045 2.81789ZM13.0514 6.19211L4.75 14.4927V16.2501H6.50734L14.8083 7.94906L13.0514 6.19211ZM15.869 6.8884L14.1121 5.1315L15.366 3.87771C15.4626 3.78088 15.6202 3.77991 15.7187 3.87839L17.1217 5.28139C17.2198 5.3795 17.2198 5.53762 17.1217 5.63573L15.869 6.8884ZM2.25 21.0001C2.25 20.5858 2.58579 20.2501 3 20.2501H21C21.4142 20.2501 21.75 20.5858 21.75 21.0001C21.75 21.4143 21.4142 21.7501 21 21.7501H3C2.58579 21.7501 2.25 21.4143 2.25 21.0001Z"
        fill="currentColor"
      />
    </svg>
  );
}

const IconLearningWriting = React.memo(IconLearningWritingSVG);
export default IconLearningWriting;
