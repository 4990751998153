import * as React from 'react';

function IconQuoteSVG(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 9 7" fill="none" {...props}>
      <path
        d="M0.457298 6.03043V4.39406C0.457298 3.91111 0.548207 3.40258 0.730025 2.86849C0.911843 2.3344 1.16468 1.82304 1.48855 1.3344C1.81241 0.845766 2.19309 0.428153 2.63059 0.0815625L3.89196 0.984971C3.54537 1.49065 3.24991 2.01906 3.00559 2.5702C2.76128 3.12133 2.63912 3.72077 2.63912 4.36849V6.03043H0.457298ZM4.80389 6.03043V4.39406C4.80389 3.91111 4.8948 3.40258 5.07662 2.86849C5.25843 2.3344 5.51128 1.82304 5.83514 1.3344C6.159 0.845766 6.53968 0.428153 6.97718 0.0815625L8.23855 0.984971C7.89196 1.49065 7.5965 2.01906 7.35218 2.5702C7.10787 3.12133 6.98571 3.72077 6.98571 4.36849V6.03043H4.80389Z"
        fill="currentColor"
      />
    </svg>
  );
}

const IconQuote = React.memo(IconQuoteSVG);
export default IconQuote;
