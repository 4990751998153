/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components';
import { FixedTopBarScholarshipScheduleNOffline } from 'shared/SchedulePage/FixedTopBarScholarship/FixedTopBarScholarshipScheduleNOffline';
import { fromScreen } from 'utils/media-query/responsive.util';
import MainLayout from '../components/Layout/MainLayout';
import HeaderSchedulePage from '../shared/Header/HeaderSchedulePage';
import MySEO from '../shared/MySEO';
import { graphql } from 'gatsby';
import { LinearthinkingHeaderV3 } from 'shared/Linearthinking/LinearthinkingHeaderV3';
import { NewsLinearThinking } from 'shared/Linearthinking/NewsLinearThinking';
import LinearthinkingCertificatesV3 from 'shared/Linearthinking/LinearthinkingCertificatesV3';
import TalkAboutLinearthinking from 'shared/Linearthinking/TalkAboutLinearthinking';
import LinearthinkingDifferencesAndApplyToSkills from 'shared/Linearthinking/LinearthinkingDifferencesAndApplyToSkills';
import { colorsV2 } from 'style/colors-v2';
import YoutubeVideoCoursesV3 from 'shared/Linearthinking/YoutubeVideoCoursesV3';
import LinearthinkingQuote from 'shared/Linearthinking/LinearthinkingQuote';
import HonorRoll from 'components/HonorRoll';
import LinearThinkingReviews from 'components/LinearThinkingReviews';
import LinearThinkingBooks from 'components/LinearThinkingBooks';
import LinearThinkingSharing from 'components/LinearThinkingSharing';

const Container = styled.div`
  ${fromScreen(776)} {
    .review-title {
      text-align: center;
    }
  }
  .responsive-section {
    margin: 0 auto;
    padding-left: 16px;
    padding-right: 16px;
    ${fromScreen(458)} {
      max-width: 425px;
      padding-left: 0;
      padding-right: 0;
    }

    ${fromScreen(776)} {
      row-gap: 100px;
      max-width: 712px;
    }

    ${fromScreen(1144)} {
      max-width: 1080px;
    }
  }

  .book-section {
    background: linear-gradient(180deg, #ffffff 0%, #edf2fd 100%);
  }

  .differences-section {
    background: ${colorsV2.neutral5};
  }

  .quote-section {
    background: ${colorsV2.neutral5};
  }
`;

const Space = styled.div`
  > .responsive-section {
    padding-top: 64px;
    padding-bottom: 64px;

    ${fromScreen(776)} {
      padding-top: 100px;
      padding-bottom: 100px;
    }
  }
  > .book {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const LandingLinearthinkingPage = ({ location, data }) => {
  return (
    <MainLayout
      renderCustomHeader={() => <HeaderSchedulePage />}
      noHeader
      hideSideCTA
      showFooterRegister
    >
      <MySEO
        slug={location.pathname}
        h1="Linearthinking - Hệ phương pháp Tiếng Anh Tư Duy dành cho người Việt"
        hidePostfix
        title="LinearThinking - Tiếng Anh Tư Duy Cho Người Việt"
        description="LinearThinking là phương pháp học tiếng anh tư duy dành cho người Việt độc quyền do DOL sáng tạo giúp việc học tiếng Anh trở nên đơn giản hơn."
      />
      <FixedTopBarScholarshipScheduleNOffline />

      <Container className="gioi-thieu-linearthinking-page">
        <LinearthinkingHeaderV3 />

        <NewsLinearThinking />

        <Space>
          <div className="responsive-section">
            <LinearthinkingCertificatesV3 />
          </div>
        </Space>

        <HonorRoll />

        <Space>
          <div className="responsive-section">
            <LinearThinkingReviews
              title="Cảm nhận của học viên về Linearthinking"
              subtitle="từ học viên và phụ huynh"
              reviewsCount="300+"
            />
          </div>
        </Space>

        <div className="differences-section">
          <Space>
            <div className="responsive-section">
              <LinearthinkingDifferencesAndApplyToSkills />
            </div>
          </Space>
        </div>

        <Space>
          <div className="responsive-section">
            <TalkAboutLinearthinking />
          </div>
        </Space>

        <Space>
          <div className="responsive-section">
            <YoutubeVideoCoursesV3 blogs={data.allLinearThinkingBlog.nodes} />
          </div>
        </Space>

        <LinearThinkingBooks />

        <Space>
          <div className="responsive-section">
            <LinearThinkingSharing />
          </div>
        </Space>

        <div className="quote-section">
          <Space>
            <div className="responsive-section">
              <LinearthinkingQuote />
            </div>
          </Space>
        </div>
      </Container>
    </MainLayout>
  );
};

export default LandingLinearthinkingPage;

export const pageQuery = graphql`
  query LinearThinkingBlogQuery {
    allLinearThinkingBlog(limit: 6) {
      nodes {
        id
        page {
          dol {
            title
            description
            fileInfo {
              url
              alt
              path
              name
            }
          }
          url
        }
      }
    }
  }
`;
