import * as React from 'react';
import styled from 'styled-components';
import { Typography } from '../../components/DesignSystemV2/Typography';
import IconUserGroup from 'components/Icon/SVGIcons/IconUserGroup';
import { withHomePageSliderSection } from 'components/withHomePageSliderSection';
import { colorsV2 } from 'style/colors-v2';
import {
  LinearThinkingNewsSlide,
  LinearThinkingNewsSlideItem
} from 'components/LinearThinkingNews';
import { toScreen } from 'utils/media-query/responsive.util';

const dummyNews = [
  {
    logo: 'cm8h0zcx61asc07zojoxwpwea',
    logoName: 'Đại học Kinh tế TP.HCM',
    thumbnail: 'cm8h1668b1o3c07196omvl6go',
    newsTitle:
      'DOL đồng hành cùng Bộ Giáo Dục & Đào Tạo TPHCM trao thưởng 30 em HS Olympic quốc tế',
    actionUrl:
      'https://www.dolenglish.vn/blog/dol-english-tro-thanh-doi-tac-chien-luoc-cua-khoa-ngoai-ngu-dai-hoc-kinh-te-tphcm'
  },
  {
    logo: 'NxefGrATqCgLcIfu4rmS',
    logoName: 'Báo Nhân Dân',
    thumbnail: 'cm8h1ck2gakfa07zqodevebdt',
    newsTitle:
      'Revolutionising English learning: using mathematical logic to learn English',
    actionUrl:
      'https://en.nhandan.vn/revolutionising-english-learning-using-mathematical-logic-to-learn-english-post136192.html'
  },
  {
    logo: 'cm8h1notu1qlm07194n5wsnkn',
    logoName: 'Kênh 14',
    thumbnail: 'cm8h1s1gd3kyn0714sj5l9jo2',
    newsTitle:
      'Bỏ túi ngay phương pháp cực “đỉnh” giúp giải quyết 4 kỹ năng trong IELTS',
    actionUrl:
      'https://kenh14.vn/hoc-ielts-mai-khong-tien-bo-bo-tui-ngay-phuong-phap-cuc-dinh-giup-giai-quyet-4-ky-nang-trong-ielts-20240422170249011.chn'
  },
  {
    logo: 'cm8imm6490l5m0719okck6hvx',
    logoName: 'Báo Giáo dục và Thời đại',
    thumbnail: 'cm8imoabp0lcq07191dgtomzn',
    newsTitle:
      'Linearthinking: Phương pháp đứng đằng sau hàng loạt điểm 7.5, 8.0, 8.5 IELTS',
    actionUrl:
      'https://giaoducthoidai.vn/linearthinking-phuong-phap-dung-dang-sau-hang-loat-diem-75-80-85-ielts-post629419.html'
  },
  {
    logo: 'cm8imqh3s0mix0715g2qa1x0q',
    logoName: 'Báo VnExpress',
    thumbnail: 'cm8imrqeh0lp507198ydzrj8l',
    newsTitle: 'How to apply LinearThinking method to master English faster',
    actionUrl:
      'https://e.vnexpress.net/news/news/education/how-to-apply-linearthinking-method-to-master-english-faster-4762528.html'
  },
  {
    logo: 'cm8imtidm0ms60715jvk6u037',
    logoName: 'Báo Dân trí',
    thumbnail: 'cm8ibe67o76pg0716imswjhg6',
    newsTitle: 'DOL English và hệ phương pháp học tiếng Anh vượt trội',
    actionUrl:
      'https://dantri.com.vn/giao-duc/dol-english-va-he-phuong-phap-hoc-tieng-anh-vuot-troi-20230921140829982.htm'
  },
  {
    logo: 'cm8imw6fe0lzn07195dyksbmh',
    logoName: 'Báo VnExpress',
    thumbnail: 'cm8imx1bo0nck0715adzz9e2b',
    newsTitle: 'Đạt 9.0 IELTS nhờ áp dụng tư duy "cụ thể hóa"',
    actionUrl:
      'https://vnexpress.net/dat-9-0-ielts-nho-ap-dung-tu-duy-cu-the-hoa-4833482.html'
  },
  {
    logo: 'BSZGpl9SGCgIqhhLyKeL',
    logoName: 'Báo Tuổi trẻ',
    thumbnail: 'cm8imyhv30ni30715a0zq22mn',
    newsTitle:
      "Struggling with English? This Vietnamese EdTech firm's math-powered approach might be the answer",
    actionUrl:
      'https://tuoitrenews.vn/news/education/20240709/struggling-with-english-this-vietnamese-edtech-firm-s-mathpowered-approach-might-be-the-answer/80860.html'
  },
  {
    logo: 'cm8imzuyr0mlp07192byaruvd',
    logoName: 'Báo Tiền Phong ',
    thumbnail: 'cm8in0kpx0ms20719hq0ue8vr',
    newsTitle:
      'Giúp học sinh học tiếng Anh hiệu quả khi áp dụng tư duy toán học',
    actionUrl:
      'https://tienphong.vn/giup-hoc-sinh-hoc-tieng-anh-hieu-qua-khi-ap-dung-tu-duy-toan-hoc-post1661346.tpo'
  },
  {
    logo: 'qOx59xJFQiQ2tJ8sGPvw',
    logoName: 'VTV Online',
    thumbnail: 'cm8in1m5b0n1n07191kanv2tu',
    newsTitle:
      'Linearthinking - Phương Pháp học Reading Tư Duy không dùng Scan SKim',
    actionUrl:
      'https://vtv.vn/giao-duc/tang-cuong-hieu-qua-day-va-hoc-tieng-anh-qua-tu-duy-linearthinking-20241007005953479.htm'
  },
  {
    logo: 'ENYIuivMS4y9o1ihcQNR',
    logoName: 'VTV7',
    thumbnail: 'cm8in2zat0o2z0715xyve470t',
    newsTitle:
      'Linearthinking - Phương Pháp học Reading Tư Duy không dùng Scan SKim',
    actionUrl: 'https://www.youtube.com/watch?v=Izb8He7q3Cw'
  },
  {
    logo: 'cm8in44tf0niq07198df2m1pv',
    logoName: 'VTV1',
    thumbnail: 'cm8in55k80no10719jc6w9mpt',
    newsTitle: 'Xu hướng học và thi IELTS bằng phương pháp tư duy',
    actionUrl: 'https://www.facebook.com/tintucvtv24/videos/1635196277402602'
  }
];
const NewsAboutDolWrapper = styled.div`
  display: grid;
  gap: 48px;
  text-align: center;
  padding: 72px 0;

  ${toScreen(775)} {
    padding: 64px 0;
  }

  .rfm-initial-child-container {
    align-items: start;
  }
`;

const HeadlineWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  color: ${colorsV2.primary100};
`;

export const NewsLinearThinkingDolV3 = ({ news = [] }) => {
  return (
    <NewsAboutDolWrapper>
      <HeadlineWrapper>
        <IconUserGroup />
        <Typography variant="medium/14-20" color="inherit">
          Linearthinking được tin tưởng bởi
        </Typography>
      </HeadlineWrapper>
      <LinearThinkingNewsSlide>
        {news.map((newsitem, index) => (
          <LinearThinkingNewsSlideItem key={index} {...newsitem} />
        ))}
      </LinearThinkingNewsSlide>
    </NewsAboutDolWrapper>
  );
};

export const NewsLinearThinking = withHomePageSliderSection(
  () => {
    return <NewsLinearThinkingDolV3 news={dummyNews} />;
  },
  {},
  {}
);
