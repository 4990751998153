import * as React from 'react';

function IconEssentialHistoryListSVG(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M9 6.5C8.44772 6.5 8 6.94772 8 7.5C8 8.05228 8.44772 8.5 9 8.5H15C15.5523 8.5 16 8.05228 16 7.5C16 6.94772 15.5523 6.5 15 6.5H9Z"
        fill="currentColor"
      />
      <path
        d="M9 10.5C8.44772 10.5 8 10.9477 8 11.5C8 12.0523 8.44772 12.5 9 12.5H12C12.5523 12.5 13 12.0523 13 11.5C13 10.9477 12.5523 10.5 12 10.5H9Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.66102 2.00001C2.17923 2.00308 1 3.21685 1 4.6875V7.5C1 8.66186 1.9335 9.625 3.11111 9.625H4.33333V19.3125C4.33333 20.785 5.51556 22 7 22H19.7778C21.569 22 23 20.5332 23 18.75V17.625C23 16.4631 22.0665 15.5 20.8889 15.5H19.6641V5.25C19.6641 3.46682 18.2331 2 16.4418 2H3.66406L3.66102 2.00001ZM3.66667 4C3.31015 4 3 4.29606 3 4.6875V7.5C3 7.58078 3.06142 7.625 3.11111 7.625H4.33333V4.6875C4.33333 4.29606 4.02318 4 3.66667 4ZM6.24499 4C6.30266 4.21982 6.33333 4.45032 6.33333 4.6875V19.3125C6.33333 19.7039 6.64349 20 7 20C7.35651 20 7.66667 19.7039 7.66667 19.3125V17.625C7.66667 16.4631 8.60017 15.5 9.77778 15.5H17.6641V5.25C17.6641 4.5479 17.1052 4 16.4418 4H6.24499ZM9.77778 17.5C9.72809 17.5 9.66667 17.5442 9.66667 17.625V19.3125C9.66667 19.5497 9.63599 19.7802 9.57833 20H19.7778C20.4411 20 21 19.4521 21 18.75V17.625C21 17.5442 20.9386 17.5 20.8889 17.5H9.77778Z"
        fill="currentColor"
      />
    </svg>
  );
}

const IconEssentialHistoryList = React.memo(IconEssentialHistoryListSVG);
export default IconEssentialHistoryList;
