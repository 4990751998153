import React from 'react';

const IconTwoUsers = ({ size = 20, color = 'currentColor' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.58923 8.00509C4.58923 6.85843 5.51878 5.92887 6.66545 5.92887C7.81211 5.92887 8.74166 6.85843 8.74166 8.00509C8.74166 9.15175 7.81211 10.0813 6.66545 10.0813C5.51878 10.0813 4.58923 9.15175 4.58923 8.00509ZM6.66545 4.26221C4.59831 4.26221 2.92257 5.93795 2.92257 8.00509C2.92257 10.0722 4.59831 11.748 6.66545 11.748C8.73258 11.748 10.4083 10.0722 10.4083 8.00509C10.4083 5.93795 8.73258 4.26221 6.66545 4.26221ZM12.9177 8.83044C12.9177 8.1396 13.4777 7.57957 14.1686 7.57957C14.8594 7.57957 15.4194 8.1396 15.4194 8.83044C15.4194 9.52127 14.8594 10.0813 14.1686 10.0813C13.4777 10.0813 12.9177 9.52127 12.9177 8.83044ZM14.1686 5.9129C12.5572 5.9129 11.251 7.21913 11.251 8.83044C11.251 10.4417 12.5572 11.748 14.1686 11.748C15.7799 11.748 17.0861 10.4417 17.0861 8.83044C17.0861 7.21913 15.7799 5.9129 14.1686 5.9129ZM4.98396 14.249C3.6101 14.249 2.49674 15.3623 2.49674 16.7362V17.5841C2.49674 18.0443 2.12365 18.4174 1.66341 18.4174C1.20317 18.4174 0.830078 18.0443 0.830078 17.5841V16.7362C0.830078 14.4419 2.68963 12.5823 4.98396 12.5823H8.34703C10.6414 12.5823 12.5009 14.4419 12.5009 16.7362V17.5841C12.5009 18.0443 12.1278 18.4174 11.6676 18.4174C11.2073 18.4174 10.8342 18.0443 10.8342 17.5841V16.7362C10.8342 15.3623 9.72089 14.249 8.34703 14.249H4.98396ZM14.1687 12.5823C13.7085 12.5823 13.3354 12.9554 13.3354 13.4156C13.3354 13.8759 13.7085 14.249 14.1687 14.249H15.0874C16.4613 14.249 17.5746 15.3623 17.5746 16.7362V17.5841C17.5746 18.0443 17.9477 18.4174 18.408 18.4174C18.8682 18.4174 19.2413 18.0443 19.2413 17.5841V16.7362C19.2413 14.4419 17.3818 12.5823 15.0874 12.5823H14.1687Z"
        fill={color}
      />
    </svg>
  );
};

export default IconTwoUsers;
