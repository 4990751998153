export const PAGE_SIZE = 6;

export const firstPageData = [
  {
    cover: {
      url: 'https://media.dolenglish.vn/PUBLIC/MEDIA/1_2024-07-09030554.jpg',
      alt: 'CEO Lê Đình Lực hạnh phúc nhận quà lưu niệm từ Phó Chủ tịch nước Võ Thị Ánh Xuân vì những đóng góp cho nền giáo dục Việt Nam'
    },
    description:
      'DOL English vinh dự được đồng hành với Bộ Giáo dục & Đào tạo trong sự kiện Lễ Tuyên dương Học sinh Đoạt giải Olympic và Khoa học Kỹ thuật Quốc tế năm 2023, mang đến cho các tài năng trẻ nhiều suất học bổng với tổng giá trị lên đến 3,3 tỷ đồng.',
    title:
      'CEO Lê Đình Lực (DOL English) đồng hành cùng tài năng Việt vươn tới đỉnh cao Olympic quốc tế',
    page: {
      url: 'https://www.dolenglish.vn/blog/dol-english-dong-hanh-cung-tai-nang-viet-olympic-quoc-te'
    }
  },
  {
    cover: {
      url: 'https://media.dolenglish.vn/PUBLIC/MEDIA/Tp_hun_Linearthinking_2.jpeg',
      alt: 'DOL vinh dự đồng hành cùng Bộ GD&ĐT tại buổi tập huấn'
    },
    description:
      'Vào tháng 10/2024 vừa qua, DOL English đã nhận lời mời từ Trung tâm Phát triển Giáo dục và Đào tạo phía Nam - Bộ Giáo dục và Đào tạo, Tạp chí Giáo dục TP.HCM tập huấn cho gần 400 giáo viên THCS và THPT trên địa bàn TP.HCM với chủ đề “Nâng cao hiệu quả dạy và học tiếng Anh bằng tư duy Linearthinking”.',
    title:
      'Linearthinking được Bộ giáo dục tổ chức tập huấn cho gần 400 giáo viên tiếng Anh THCS và THPT TP.HCM',
    page: {
      url: 'https://www.dolenglish.vn/blog/dol-english-duoc-bo-giao-duc-va-dao-tao-moi-tap-huan-cho-giao-vien-thpt-thcs-tphcm'
    }
  },
  {
    cover: {
      url: 'https://media.dolenglish.vn/PUBLIC/MEDIA/a-gdtd-9569.jpg',
      alt: ''
    },
    description:
      'Sáng 06/08/2024, tại Hội trường Trường THPT chuyên Hà Nội - Amsterdam (số 1 Hoàng Minh Giám, quận Cầu Giấy, Hà Nội), Sở GD&ĐT Hà Nội phối hợp với Báo Giáo dục và Thời đại cùng DOL English tổ chức buổi tập huấn với chủ đề “Nâng cao hiệu quả dạy và học tiếng Anh bằng tư duy Linearthinking” nhằm bồi dưỡng giáo viên tiếng Anh phổ thông Thành phố Hà Nội.',
    title:
      'Gần 200 giáo viên tiếng Anh THPT Hà Nội tham gia tập huấn về pp Linearthinking',
    page: {
      url: 'https://www.dolenglish.vn/blog/so-gddt-ha-noi-cung-dol-tap-huan-cho-gan-200-giao-vien-tieng-anh'
    }
  },
  {
    cover: {
      url: 'https://media.dolenglish.vn/PUBLIC/MEDIA/462640940_501107156278803_2125582910084559793_n.jpg',
      alt: 'DOL IELTS Đình Lực - Học viện Tiếng Anh tư duy đầu tiên tại Việt Nam là đơn vị giảng dạy IELTS chính thức của trường Phổ Thông Năng Khiếu ĐH Quốc Gia TP. HCM'
    },
    description:
      'DOL IELTS Đình Lực vinh hạnh được trở thành đơn vị giảng dạy IELTS chính thức của Trường Phổ Thông Năng Khiếu ĐH Quốc Gia TP. HCM. Sở hữu phương pháp Linearthinking độc quyền được cục Sở Hữu Trí Tuệ cấp bằng chứng nhận, DOL giúp các bạn học sinh học IELTS 1 cách tư duy, từ đó tăng điểm thần tốc, tiết kiệm ½ thời gian học so với phương pháp học thông thường.',
    title:
      'Linearthinking Được Giảng Dạy Tại Trường Phổ Thông Năng Khiếu ĐH Quốc Gia TP. HCM',
    page: {
      url: 'https://www.dolenglish.vn/blog/dol-english-don-vi-giang-day-ielts-chinh-thuc-cua-truong-pho-thong-nang-khieu'
    }
  },
  {
    cover: {
      url: 'https://media.dolenglish.vn/PUBLIC/MEDIA/dd348bd3-a6cd-48c7-b502-10278cbca14a.jpg',
      alt: 'Thầy Huy Hùng đại diện DOL IELTS Đình Lực cùng BGH Trường THPT chuyên Hà Nội - Amsterdam'
    },
    description:
      'Sáng 18/09, các bạn học sinh THPT chuyên Hà Nội - Amsterdam đã có cơ hội tìm hiểu về Linearthinking - phương pháp học IELTS tư duy độc quyền tại DOL English, thông qua chương trình hướng nghiệp “Sáng tư duy, giỏi ngoại ngữ, nắm giữ tương lai”.',
    title:
      'Linearthinking đến với học sinh THPT chuyên Hà Nội - Amsterdam, tiếp tục chuỗi hành trình lan toả tri thức',
    page: {
      url: 'https://www.dolenglish.vn/blog/hs-truong-thpt-chuyen-ha-noi-amsterdam-hao-hung-khi-tim-hieu-ve-linearthinking'
    }
  },
  {
    cover: {
      url: 'https://media.dolenglish.vn/PUBLIC/MEDIA/241128_-_Linear_x_THPT_chuyen_Le_Hong_Phong_2.jpg',
      alt: 'Chuyến đi Linearthinking đã có cơ hội cập bến ngôi trường “con nhà người ta” THPT chuyên Lê Hồng Phong'
    },
    description:
      'Ngày 9/11, các bạn học sinh THPT chuyên Lê Hồng Phong đã có cơ hội được làm quen với Linearthinking - hệ phương pháp học tiếng Anh tư duy độc quyền tại DOL English - IELTS Đình Lực thông qua workshop “LHPers chinh phục 8.0+ IELTS cùng hệ phương pháp Linearthinking” thú vị và hấp dẫn.',
    title:
      'Linearthinking lan toả đến THPT chuyên Lê Hồng Phong với workshop “LHPers chinh phục 8.0+ IELTS cùng hệ phương pháp Linearthinking”',
    page: {
      url: 'https://www.dolenglish.vn/blog/linearthinking-lan-toa-den-thpt-chuyen-le-hong-phong'
    }
  }
].map((item, index) => ({
  ...item,
  id: (index + 1).toString()
}));

const firstPageLength = firstPageData.length;

export const randomData = [
  {
    cover: {
      url: 'cm8mh7ok9meke0715swie2qlh',
      alt: 'Buổi Workshop với sự tham gia của 1000 học sinh THPT Cầu Giấy cùng BGH Nhà trường'
    },
    title:
      "Phương pháp học tư duy Linearthinking 'tới' trường THPT Cầu Giấy | Báo Giáo dục và Thời đại Online",
    description:
      'DOL English- IELTS Đình Lực Học viện IELTS top 1 TP. HCM chính thức bắt đầu hành trình mang Linearthinking đến với Thủ đô Hà Nội.',
    page: {
      url: 'https://giaoducthoidai.vn/phuong-phap-hoc-tu-duy-linearthinking-toi-truong-thpt-cau-giay-post634157.html'
    }
  },
  {
    cover: {
      url: 'cm8mh7oyrmkd60719nq71skvn',
      alt: 'Diễn giả Ms Ngọc Anh chia sẻ với học sinh trong Workshop'
    },
    title:
      'Hành trình DOL English mang Linearthinking tới THPT Trần Phú | Báo Giáo dục và Thời đại Online',
    description:
      'GD&TĐ - Vừa qua, DOL English tổ chức Workshop "GenZ chinh phục ngoại ngữ dễ dàng nhờ phương pháp học tiếng anh tư duy Linearthinking".',
    page: {
      url: 'https://giaoducthoidai.vn/hanh-trinh-dol-english-mang-linearthinking-toi-thpt-tran-phu-post634156.html'
    }
  },
  {
    cover: { url: 'cm8mh7ozcmems0715duvxzlzj', alt: '' },
    title:
      'HS hào hứng với phương pháp học tiếng Anh tư duy Linearthinking | Báo Giáo dục và Thời đại Online',
    description:
      'Học sinh trường THPT Khương Hạ (Thanh Xuân, Hà Nội) hào hứng với phương pháp học tiếng Anh tư duy Linearthinking của DOL IELTS Đình Lực.',
    page: {
      url: 'https://giaoducthoidai.vn/hao-hung-voi-phuong-phap-hoc-tieng-anh-tu-duy-linearthinking-post634842.html'
    }
  },
  {
    cover: { url: 'cm8mh7ooxmem207157duk7yzw', alt: '' },
    title:
      'Trau dồi kỹ năng ngoại ngữ cùng DOL English | Báo Giáo dục và Thời đại Online',
    description:
      'Học sinh trường Tiểu học - THCS - THPT Thực nghiệm (Ba Đình, Hà Nội) chăm chú lắng nghe phương pháp Linearthinking của DOL English.',
    page: {
      url: 'https://giaoducthoidai.vn/trau-doi-ky-nang-ngoai-ngu-cung-dol-english-post634844.html'
    }
  },
  {
    cover: {
      url: 'cm8mh7ozememu071506lq3240',
      alt: 'Thầy Phúc - đại diện DOL English gửi món quà tặng BGH THPT Trần Hưng Đạo - Hà Đông'
    },
    title:
      'DOL IELTS Đình Lực khuấy động sân trường THPT Trần Hưng Đạo | Báo Giáo dục và Thời đại Online',
    description:
      'DOL English học viện IELTS Top 1 TPHCM tiếp tục cuộc hành trình mang hệ phương pháp thông minh Linearthinking đến gần hơn tới bạn trẻ thủ đô.',
    page: {
      url: 'https://giaoducthoidai.vn/dol-ielts-dinh-luc-khuay-dong-san-truong-thpt-tran-hung-dao-post635108.html'
    }
  },
  {
    cover: {
      url: 'cm8mh7p2nmenh0715q6r4jgr7',
      alt: 'Cô Thanh Ngân- đại diện DOL English gửi tặng món quà tới BGH'
    },
    title:
      "Học sinh Hoàng Cầu 'vỡ oà' khi được tiếp cận phương pháp học mới | Báo Giáo dục và Thời đại Online",
    description:
      'Workshop “Gen Z chinh phục ngoại ngữ dễ dàng nhờ phương pháp học tiếng Anh tư duy Linearthinking” đã được tổ chức tại trường THPT Hoàng Cầu.',
    page: {
      url: 'https://giaoducthoidai.vn/hoc-sinh-hoang-cau-vo-oa-khi-duoc-tiep-can-phuong-phap-hoc-moi-post635109.html'
    }
  },
  {
    cover: { url: 'cm8mh7ok5mkar0719rea5p9rs', alt: '' },
    title:
      'Trường THPT Đoàn Kết - Hai Bà Trưng hào hứng với Linearthinking | Báo Giáo dục và Thời đại Online',
    description:
      'DOL IELTS Đình Lực đã có cơ hội mang Linearthinking tới trường THPT Đoàn Kết - Hai Bà Trưng (Hà Nội).',
    page: {
      url: 'https://giaoducthoidai.vn/truong-thpt-doan-ket-hai-ba-trung-hao-hung-voi-linearthinking-post636101.html'
    }
  },
  {
    cover: { url: 'cm8mh7oktmekr0715ixih30h0', alt: '' },
    title:
      "Phương pháp tiếng Anh Linearthinking 'đốn tim' học sinh | Báo Giáo dục và Thời đại Online",
    description:
      'DOL IELTS Đình Lực đã đến THPT Lê Quý Đôn, Hà Đông, HN với chủ đề: “GenZ chinh phục ngoại ngữ dễ dàng nhờ phương pháp học tiếng Anh tư duy thông minh”.',
    page: {
      url: 'https://giaoducthoidai.vn/phuong-phap-tieng-anh-linearthinking-don-tim-hoc-sinh-post636097.html'
    }
  },
  {
    cover: { url: 'cm8mh7p33mke5071921zv1fdu', alt: '' },
    title:
      'Học sinh hào hứng tìm hiểu tiếng Anh tư duy Linearthinking | Báo Giáo dục và Thời đại Online',
    description:
      'Học sinh trường THPT Phạm Hồng Thái đã hào hứng tìm hiểu phương pháp tiếng Anh tư duy Linearthinking trong khuôn khổ chương trình hướng nghiệp.',
    page: {
      url: 'https://giaoducthoidai.vn/hoc-sinh-hao-hung-tim-hieu-tieng-anh-tu-duy-linearthinking-post637866.html'
    }
  },
  {
    cover: {
      url: 'cm8mh7p0tmkdr0719nisv0m6r',
      alt: 'Diễn giả của buổi Workshop tại trường phổ thông liên cấp H.A.S (Hanoi Adelaide School) chính là Ms Ngọc Anh - giáo viên tại DOL IELTS Đình Lực với kinh nghiệm giảng dạy IELTS cực kỳ dày dặn và đặc biệt là profile ngưỡng mộ (8.0 IELTS, 9.0 Reading, Chứng chỉ Giảng dạy Tiếng Anh Quốc tế TESOL, Thạc sỹ Tài chính bằng Distinction - University of the West of England - UWE Bristol).'
    },
    title:
      'DOL IELTS Đình Lực và hành trình đầy cảm xúc đến trường H.A.S | Báo Giáo dục và Thời đại Online',
    description:
      'DOL IELTS Đình Lực đã vinh dự mang Linearthinking đến Trường phổ thông liên cấp H.A.S (Hanoi Adelaide School).',
    page: {
      url: 'https://giaoducthoidai.vn/dol-ielts-dinh-luc-va-hanh-trinh-day-cam-xuc-den-truong-has-post637206.html'
    }
  },
  {
    cover: { url: 'cm8mh7p0qmen60715g7w6o7ig', alt: '' },
    title:
      'Gen Z hào hứng tìm hiểu phương pháp tiếng Anh Linearthinking  | Báo Giáo dục và Thời đại Online',
    description:
      'DOL IELTS Đình Lực đã đi qua gần 20 điểm trường ở Hà Nội và nhận về những phản hồi tích cực của nhiều các bạn học sinh thủ đô.',
    page: {
      url: 'https://giaoducthoidai.vn/gen-z-hao-hung-tim-hieu-phuong-phap-tieng-anh-linearthinking-post637963.html'
    }
  },
  {
    cover: { url: 'cm8mh7okbmkau07197kuw8vcs', alt: '' },
    title:
      'Học sinh trường THP Phan Huy Chú tìm hiểu Linearthinking | Báo Giáo dục và Thời đại Online',
    description:
      'Trong chương trình hướng nghiệp “Sáng tư duy, giỏi ngoại ngữ, nắm giữ tương lai”, DOL IELTS Đình Lực đã tới trường THPT Phan Huy Chú - Đống Đa.',
    page: {
      url: 'https://giaoducthoidai.vn/hoc-sinh-truong-thpt-phan-huy-chu-tim-hieu-linearthinking-post638425.html'
    }
  },
  {
    cover: { url: 'cm8mh7p11mkdw07194kx9v23s', alt: '' },
    title:
      'Học tiếng Anh tư duy Linearthinking gây ấn tượng với học sinh | Báo Giáo dục và Thời đại Online',
    description:
      'Chương trình hướng nghiệp “Sáng tư duy, giỏi ngoại ngữ, nắm giữ tương lai” đã tới trường THPT Đào Duy Từ (Hà Nội).',
    page: {
      url: 'https://giaoducthoidai.vn/hoc-tieng-anh-tu-duy-linearthinking-gay-an-tuong-voi-hoc-sinh-post638427.html'
    }
  },
  {
    cover: { url: 'cm8mh7p9xmenw0715h6qs78g2', alt: '' },
    title:
      'Học sinh THPT Thăng Long sôi động với phương pháp Linearthinking | Báo Giáo dục và Thời đại Online',
    description:
      'Chương trình hướng nghiệp “Sáng tư duy, giỏi ngoại ngữ, nắm giữ tương lai”, DOL IELTS Đình Lực đã mang tiếng Anh tư duy Linearthinking tới Hà Nội.',
    page: {
      url: 'https://giaoducthoidai.vn/hoc-sinh-thpt-thang-long-soi-dong-voi-phuong-phap-linearthinking-post638540.html'
    }
  },
  {
    cover: { url: 'cm8mh7p0nmen40715a2ct2um6', alt: '' },
    title:
      "Linearthinking 'đốn tim' học sinh THPT Lê Quý Đôn - Đống Đa | Báo Giáo dục và Thời đại Online",
    description:
      'Ngày 13/5, chương trình hướng nghiệp “Sáng tư duy, giỏi ngoại ngữ, nắm giữ tương lai” đã tới trường THPT Lê Quý Đôn - Đống Đa.',
    page: {
      url: 'https://giaoducthoidai.vn/linearthinking-don-tim-hoc-sinh-thpt-le-quy-don-dong-da-post638557.html'
    }
  },
  {
    cover: {
      url: 'cm8mh7ow0mkcs0719p1ef448x',
      alt: 'Người đồng hành với các bạn trường Phổ thông liên cấp Đa Trí Tuệ là diễn giả Trần Đức Quân - một giảng viên sở hữu thành tích ấn tượng với chứng chỉ IELTS 8.0; kinh nghiệm giảng dạy trong 5 năm; tốt nghiệp Giỏi ngành Sư phạm tiếng Anh tại Đại học Ngoại ngữ - Đại học Quốc gia Hà Nội; học bổng ULIS Posco Scholarship; Cựu học sinh trường THPT Hanoi-Amsterdam. Ngoài ra thầy còn là cử nhân ngành TEFL của Đại học Ngoại ngữ và là đại diện Đại sứ quán Anh trong cuộc mô phỏng Ngoại giao năm 2019.'
    },
    title:
      'Học sinh trường Đa Trí Tuệ hào hứng tìm hiểu Linearthinking | Báo Giáo dục và Thời đại Online',
    description:
      'Chương trình hướng nghiệp “Sáng tư duy, giỏi Ngoại ngữ, nắm giữ tương lai” đã tới trường Phổ thông liên cấp Đa Trí Tuệ vào ngày 15/5.',
    page: {
      url: 'https://giaoducthoidai.vn/hoc-sinh-truong-da-tri-tue-hao-hung-tim-hieu-linearthinking-post638743.html'
    }
  },
  {
    cover: { url: 'cm8mh7ok9mekc0715ny4ia7ny', alt: '' },
    title:
      'Học sinh trường THPT Đống Đa hào hứng tiếp cận tiếng Anh tư duy | Báo Giáo dục và Thời đại Online',
    description:
      'Chương trình hướng nghiệp “Sáng tư duy, giỏi ngoại ngữ, nắm chắc tương lai” đã tới trường THPT Đống Đa, Hà Nội.',
    page: {
      url: 'https://giaoducthoidai.vn/hoc-sinh-truong-thpt-dong-da-hao-hung-tiep-can-tieng-anh-tu-duy-post638890.html'
    }
  },
  {
    cover: { url: 'cm8mh7pebmkeq0719onbdfsb3', alt: '' },
    title:
      'Hành trình mang Linearthinking tới trường THPT Yên Hoà | Báo Giáo dục và Thời đại Online',
    description:
      'Chương trình hướng nghiệp “Sáng tư duy, giỏi ngoại ngữ, nắm giữ tương lai” đã tới trường THPT Yên Hoà (Hà Nội).',
    page: {
      url: 'https://giaoducthoidai.vn/hanh-trinh-mang-linearthinking-toi-truong-thpt-yen-hoa-post638895.html'
    }
  },
  {
    cover: { url: 'cm8mh7p0nmen207157e17fcfj', alt: '' },
    title:
      'Linearthinking thu hút học sinh trường THPT Phan Đình Phùng | Báo Giáo dục và Thời đại Online',
    description:
      ' Chương trình hướng nghiệp “Sáng tư duy, giỏi ngoại ngữ, nắm giữ tương lai” tiếp tục được tổ chức tại trường THPT Phan Đình Phùng (Hà Nội).',
    page: {
      url: 'https://giaoducthoidai.vn/linearthinking-thu-hut-hoc-sinh-truong-thpt-phan-dinh-phung-post639023.html'
    }
  },
  {
    cover: { url: 'cm8mh7oymmkd20719ripcffrw', alt: '' },
    title:
      'Học sinh trường THPT Nguyễn Gia Thiều hào hứng với Linearthinking | Báo Giáo dục và Thời đại Online',
    description:
      'Workshop “Gen Z chinh phục ngoại ngữ dễ dàng nhờ phương pháp học tiếng Anh tư duy Linearthinking” đã đến với trường THPT Nguyễn Gia Thiều.',
    page: {
      url: 'https://giaoducthoidai.vn/hoc-sinh-truong-thpt-nguyen-gia-thieu-hao-hung-voi-linearthinking-post639024.html'
    }
  },
  {
    cover: {
      url: 'cm8mh7p3amenm0715ep1l2lrj',
      alt: 'Cô Ngọc Anh cùng đại diện BGH trường THCS & THPT Nguyễn Bỉnh Khiêm.'
    },
    title:
      'GenZ trường Nguyễn Bỉnh Khiêm ‘bùng nổ’ cùng Linearthinking | Báo Giáo dục và Thời đại Online',
    description:
      'Chương trình hướng nghiệp “Sáng tư duy, giỏi ngoại ngữ, nắm giữ tương lai” đã ‘cập bến’ trường THPT Nguyễn Bỉnh Khiêm (Hà Nội) vào ngày 18/5.',
    page: {
      url: 'https://giaoducthoidai.vn/genz-truong-nguyen-binh-khiem-bung-no-cung-linearthinking-post639212.html'
    }
  },
  {
    cover: { url: 'cm8mh7p7lmkeg0719ic3ce67y', alt: '' },
    title:
      "Học sinh THPT Nguyễn Tất Thành, Thanh Xuân 'vỡ òa' với phương pháp tiếng Anh mới | Báo Giáo dục và Thời đại Online",
    description:
      "Ngày 15/9, Workshop 'GenZ chinh phục ngoại ngữ dễ dàng nhờ phương pháp học tiếng Anh tư duy thông minh' đã quay trở lại với học sinh Thủ đô.",
    page: {
      url: 'https://giaoducthoidai.vn/hoc-sinh-thpt-nguyen-tat-thanh-thanh-xuan-vo-oa-voi-phuong-phap-tieng-anh-moi-post654246.html'
    }
  },
  {
    cover: { url: 'cm8mh7on6mkcc0719k96bhx21', alt: '' },
    title:
      'HS trường THPT Chuyên Hà Nội - Amsterdam hào hứng khi tìm hiểu về Linearthinking | Báo Giáo dục và Thời đại Online',
    description:
      "Chương trình hướng nghiệp 'Sáng tư duy, giỏi ngoại ngữ, nắm giữ tương lai' được tổ chức tại trường THPT Chuyên Hà Nội - Amsterdam sáng 18/9.",
    page: {
      url: 'https://giaoducthoidai.vn/hs-truong-thpt-chuyen-ha-noi-amsterdam-hao-hung-khi-tim-hieu-ve-linearthinking-post654571.html'
    }
  },
  {
    cover: { url: 'cm8mh7omjmkbr0719todv44d2', alt: '' },
    title:
      "LinearThinking 'đốn tim' học sinh trường THPT Nguyễn Siêu | Báo Giáo dục và Thời đại Online",
    description:
      "Chương trình hướng nghiệp 'Sáng tư duy, giỏi ngoại ngữ, nắm giữ tương lai' được tổ chức tại trường THPT Nguyễn Siêu sáng 18/9.",
    page: {
      url: 'https://giaoducthoidai.vn/linearthinking-don-tim-hoc-sinh-truong-thpt-nguyen-sieu-post654572.html'
    }
  },
  {
    cover: { url: 'cm8mh7pfdmkev0719vckcjhmv', alt: '' },
    title:
      'Lan tỏa phương pháp học tư duy thông minh đến học sinh trường THPT Nguyễn Trãi | Báo Giáo dục và Thời đại Online',
    description:
      'Chương trình hướng nghiệp “Sáng tư duy, giỏi ngoại ngữ, nắm giữ tương lai” tiếp tục lan tỏa đến học sinh trường THPT Nguyễn Trãi.',
    page: {
      url: 'https://giaoducthoidai.vn/lan-toa-phuong-phap-hoc-tu-duy-thong-minh-den-hoc-sinh-truong-thpt-nguyen-trai-post654574.html'
    }
  },
  {
    cover: {
      url: 'cm8mh7paomkel07196fx13isn',
      alt: 'Diễn giả của buổi workshop tại trường THPT Việt Đức là thầy thầy Phạm Huy Hùng, hiện là Academic Director tại Học viện tiếng Anh tư duy - DOL IELTS Đình Lực. Thầy Hùng được rất nhiều học viên ngưỡng mộ với thành tích “chuẩn con nhà người ta”: 2320/2400 SAT, Thạc sĩ Texas Christian University & Texas A&M University - USA, Cựu giảng viên tại ĐH Sư Phạm, ĐH KHXHNV & Khóa GRE tại LSQ Mỹ, Cựu chuyên Anh Trường Phổ thông năng khiếu TPHCM. Ngoài ra, thầy Huy Hùng còn là khách mời thân quen trên sóng truyền hình HTV9 với chuyên mục “Nhịp cầu du học”.'
    },
    title:
      'Học sinh trường THPT Việt Đức sôi nổi tìm hiểu cách học Linearthinking | Báo Giáo dục và Thời đại Online',
    description:
      "Chương trình hướng nghiệp 'Sáng tư duy, giỏi ngoại ngữ, nắm giữ tương lai' với sự tham gia của hơn 1000 học sinh trường THPT Việt Đức.",
    page: {
      url: 'https://giaoducthoidai.vn/hoc-sinh-truong-thpt-viet-duc-soi-noi-tim-hieu-cach-hoc-linearthinking-post655175.html'
    }
  },
  {
    cover: { url: 'cm8mh7oy4mkcx0719p4hf8o0s', alt: '' },
    title:
      'Học sinh trường THPT Quang Trung - Đống Đa sôi động với Linear Thinking | Báo Giáo dục và Thời đại Online',
    description:
      "Chương trình hướng nghiệp 'Sáng tư duy, giỏi ngoại ngữ, nắm giữ tương lai' đã đến với học sinh trường THPT Quang Trung - Đống Đa.",
    page: {
      url: 'https://giaoducthoidai.vn/hoc-sinh-truong-thpt-quang-trung-dong-da-soi-dong-voi-linear-thinking-post655989.html'
    }
  },
  {
    cover: {
      url: 'cm8mh7omwmell0715tmem1b6p',
      alt: 'Trước khi buổi Workshop diễn ra, rất nhiều bạn học sinh đã bị thu hút bởi cuốn sách “Thông não IELTS Reading cùng Linear” do chính giáo viên DOL biên soạn. Các bạn rất hào hứng khi được cô Ngọc Anh giới thiệu qua về cuốn sách và vô cùng thích thú khi đọc những trang sách đầu tiên.'
    },
    title:
      'Học sinh trường THPT Tây Hồ ‘bùng nổ’ cùng Linearthinking | Báo Giáo dục và Thời đại Online',
    description:
      'Chương trình hướng nghiệp “Sáng tư duy, giỏi ngoại ngữ, nắm giữ tương lai” đã đến với học sinh trường THPT Tây Hồ (Hà Nội).',
    page: {
      url: 'https://giaoducthoidai.vn/hoc-sinh-truong-thpt-tay-ho-bung-no-cung-linearthinking-post656036.html'
    }
  },
  {
    cover: { url: 'cm8mh7p2qmke10719kfgqscmy', alt: '' },
    title:
      'GenZ trường THPT Nhân Chính hào hứng tìm hiểu Linearthinking | Báo Giáo dục và Thời đại Online',
    description:
      'Ngày 30/9, Chương trình hướng nghiệp “Sáng tư duy, giỏi ngoại ngữ, nắm giữ tương lai” đã đến với trường THPT Nhân Chính.',
    page: {
      url: 'https://giaoducthoidai.vn/genz-truong-thpt-nhan-chinh-hao-hung-tim-hieu-linearthinking-post656062.html'
    }
  },
  {
    cover: {
      url: 'cm8mh7pjxmkf007191mawsg5x',
      alt: 'Diễn giả của sự kiện là cô Hoàng Thùy Linh - giáo viên dày dặn kinh nghiệm tại DOL IELTS Đình Lực với 8.0 IELTS. Ngoài ra, cô Linh còn có những thành tích đáng nể như: Tốt nghiệp loại Giỏi khoa Ngôn ngữ Anh – ĐH Hà Nội, sở hữu chứng chỉ giảng dạy tiếng Anh Quốc tế Tesol, 5 năm kinh nghiệm giảng dạy IELTS với hơn 1000 học viên thuộc các cấp độ và đã vinh dự được làm diễn giả khách mời SDG Challenge Showcase 2018 in Ireland.'
    },
    title:
      'Học sinh Trường THPT Khương Đình say mê tìm hiểu phương pháp học Linearthinking | Báo Giáo dục và Thời đại Online',
    description:
      "Chương trình hướng nghiệp 'Sáng tư duy, giỏi ngoại ngữ, nắm giữ tương lai' đã đến với gần 2000 học sinh trường THPT Khương Đình (Hà Nội).",
    page: {
      url: 'https://giaoducthoidai.vn/hoc-sinh-truong-thpt-khuong-dinh-say-me-tim-hieu-phuong-phap-hoc-linearthinking-post656157.html'
    }
  },
  {
    cover: { url: 'cm8mh7omkmelb0715f91mesll', alt: '' },
    title:
      "GenZ trường THPT Lê Lợi 'cháy hết mình' với tiếng Anh tư duy Linearthinking | Báo Giáo dục và Thời đại Online",
    description:
      'Chương trình hướng nghiệp ‘Sáng tư duy, giỏi ngoại ngữ, nắm giữ tương lai’ đã đến với học sinh trường THPT Lê Lợi (Hà Đông, Hà Nội).',
    page: {
      url: 'https://giaoducthoidai.vn/genz-truong-thpt-le-loi-chay-het-minh-voi-tieng-anh-tu-duy-linearthinking-post656159.html'
    }
  },
  {
    cover: {
      url: 'cm8mh7ornmem807153o2dje71',
      alt: 'Việc sở hữu tấm bằng IELTS ngay từ khi còn là học sinh THPT hiện đang là mong ước của khá nhiều bạn, bởi đây chính là cơ hội để các bạn để mở rộng cánh cửa bước vào các trường đại học top đầu hoặc đi du học nước ngoài.'
    },
    title:
      'Học sinh Trường THPT Quang Trung - Hà Đông ‘bùng nổ’ tìm hiểu về Linearthinking | Báo Giáo dục và Thời đại Online',
    description:
      'Ngày 2/10, chương trình hướng nghiệp “Sáng tư duy, giỏi ngoại ngữ, nắm giữ tương lai” đã được tổ chức tại Trường THPT Quang Trung - Hà Đông.',
    page: {
      url: 'https://giaoducthoidai.vn/hoc-sinh-truong-thpt-quang-trung-ha-dong-bung-no-tim-hieu-ve-linearthinking-post656203.html'
    }
  },
  {
    cover: { url: 'cm8mh7oyumemh071508eqmos6', alt: '' },
    title:
      'HS Trường THPT Trần Hưng Đạo - Thanh Xuân sôi động tìm hiểu tiếng Anh tư duy | Báo Giáo dục và Thời đại Online',
    description:
      "Chương trình hướng nghiệp 'Sáng tư duy, giỏi ngoại ngữ, nắm giữ tương lai' đã đến với học sinh trường THPT Trần Hưng Đạo - Thanh Xuân, Hà Nội.",
    page: {
      url: 'https://giaoducthoidai.vn/hs-truong-thpt-tran-hung-dao-thanh-xuan-soi-dong-tim-hieu-tieng-anh-tu-duy-post657088.html'
    }
  },
  {
    cover: { url: 'cm8mh7p8omenr07151p1c7e7g', alt: '' },
    title:
      'HS Trường THPT Đông Mỹ sôi động tham gia hướng nghiệp và tìm hiểu Linearthinking | Báo Giáo dục và Thời đại Online',
    description:
      'Gần 1000 học sinh trường THPT Đông Mỹ đãhào hứng tham dự chươngtrình hướng nghiệp ‘Sáng tư duy, giỏi ngoại ngữ, nắm giữ tương lai’.',
    page: {
      url: 'https://giaoducthoidai.vn/hs-truong-thpt-dong-my-soi-dong-tham-gia-huong-nghiep-va-tim-hieu-linearthinking-post658075.html'
    }
  },
  {
    cover: { url: 'cm8mh7ozcmkdc0719fkddy366', alt: '' },
    title:
      'Học sinh Trường THPT Chu Văn An hào hứng tìm hiểu Linearthinking | Báo Giáo dục và Thời đại Online',
    description:
      "Sáng 16/10, chương trình hướng nghiệp 'Sáng tư duy, giỏi ngoại ngữ, nắm giữ tương lai' đã đến với Trường THPT Chu Văn An (Hà Nội).",
    page: {
      url: 'https://giaoducthoidai.vn/hoc-sinh-truong-thpt-chu-van-an-hao-hung-tim-hieu-linearthinking-post657750.html'
    }
  },
  {
    cover: { url: 'cm8mh7ooymkcn07191zl9at5q', alt: '' },
    title:
      'Học sinh trường THPT Bắc Hà hào hứng tiếp cận Linearthinking | Báo Giáo dục và Thời đại Online',
    description:
      "Chương trình hướng nghiệp 'Sáng tư duy, giỏi Ngoại ngữ, nắm giữ tương lai' đã tới trường THPT Bắc Hà vào sáng ngày 28/10.",
    page: {
      url: 'https://giaoducthoidai.vn/hoc-sinh-truong-thpt-bac-ha-hao-hung-tiep-can-linearthinking-post659109.html'
    }
  },
  {
    cover: {
      url: 'cm8mh7p53mkeb0719uam0b3gc',
      alt: 'Chương trình diễn ra với sự dẫn dắt của diễn giả Đỗ Thị Ngọc Anh- giáo viên tại DOL IELTS Đình Lực với kinh nghiệm giảng dạy IELTS dày dặn: Cô sở hữu 8.0 IELTS, 9.0 Reading, Cử nhân Kinh tế bằng Đỏ tại Nga - Financial University under the Government of the Russian Federation, Thạc sỹ Tài chính bằng Distinction - University of the West of England - UWE Bristol.'
    },
    title:
      'Linearthinking khuấy động sân trường THPT Việt Nam- Ba Lan | Báo Giáo dục và Thời đại Online',
    description:
      "Chương trình hướng nghiệp 'Sáng tư duy, giỏi ngoại ngữ, nắm giữ tương lai' đã đến với Trường THPT Việt Nam - Ba Lan (Hà Nội).",
    page: {
      url: 'https://giaoducthoidai.vn/linearthinking-khuay-dong-san-truong-thpt-viet-nam-ba-lan-post659352.html'
    }
  },
  {
    cover: {
      url: 'cm8mh7omxmeln0715cfu1ud0e',
      alt: 'Người đồng hành chính với các bạn học sinh tại buổi Talkshow lần này là diễn giả Đỗ Thị Ngọc Anh - giáo viên tại DOL IELTS Đình Lực với kinh nghiệm giảng dạy IELTS dày dặn: Cô sở hữu 8.0 IELTS, 9.0 Reading, Cử nhân Kinh tế bằng Đỏ tại Nga - Financial University under the Government of the Russian Federation, Thạc sỹ Tài chính bằng Distinction - University of the West of England - UWE Bristol.'
    },
    title:
      "Phương pháp học tiếng Anh Linearthinking 'đốn tim' học sinh THPT Lý Thường Kiệt | Báo Giáo dục và Thời đại Online",
    description:
      'DOL English IELTS Đình Lực đã tổ chức thành công Talkshow "GenZ chinh phục ngoại ngữ dễ dàng với phương pháp học tiếng Anh tư duy Linearthinking".',
    page: {
      url: 'https://giaoducthoidai.vn/phuong-phap-hoc-tieng-anh-linearthinking-don-tim-hoc-sinh-thpt-ly-thuong-kiet-post667685.html'
    }
  },
  {
    cover: {
      url: 'cm8mh7oklmkb30719jspwtcjf',
      alt: 'Đồng hành với các bạn học sinh trường THPT Phúc Lợi trong buổi Talkshow chính là cô giáo Đỗ Thị Ngọc Anh - giáo viên kỳ cựu đến từ DOL English IELTS Đình Lực với profile ngưỡng mộ: 8.0 IELTS, 9.0 Reading, Cử nhân Kinh tế bằng Đỏ tại Nga - Financial University under the Government of the Russian Federation, Thạc sỹ Tài chính bằng Distinction - University of the West of England - UWE Bristol; chứng chỉ giảng dạy tiếng anh quốc tế TESOL; danh hiệu “Đại sứ quốc tế” trường Đại học Tài chính trực thuộc Chính phủ Liên bang Nga.'
    },
    title:
      'Gen Z Trường THPT Phúc Lợi bùng nổ cùng phương pháp học tiếng Anh tư duy độc đáo | Báo Giáo dục và Thời đại Online',
    description:
      'Lần đầu tiên tiếp cận phương pháp học học tiếng Anh tư duy Linearthinking độc đáo, mới lạ, các bạn học sinh đã thể hiện sự say mê, thích thú.',
    page: {
      url: 'https://giaoducthoidai.vn/gen-z-truong-thpt-phuc-loi-bung-no-cung-phuong-phap-hoc-tieng-anh-tu-duy-doc-dao-post667684.html'
    }
  },
  {
    cover: { url: 'cm8mh7om7mel50715v3n51ok6', alt: '' },
    title:
      'Phương pháp học tiếng Anh tư duy ‘đốn tim’ hơn 1.000 GenZ Trường Hoàng Văn Thụ | Báo Giáo dục và Thời đại Online',
    description:
      "Chương trình hướng nghiệp 'Sáng tư duy, giỏi ngoại ngữ, nắm giữ tương lai' đã được tổ chức tại Trường THPT Hoàng Văn Thụ (Hà Nội).",
    page: {
      url: 'https://giaoducthoidai.vn/phuong-phap-hoc-tieng-anh-tu-duy-don-tim-hon-1000-genz-truong-hoang-van-thu-post660150.html'
    }
  },
  {
    cover: {
      url: 'cm8mh7omtmkc10719me46r84o',
      alt: 'Tại đây, các bạn yêu thích việc học ngoại ngữ đã khám phá ra một trợ thủ đắc lực - phương pháp LinearThinking - học theo lối tư duy thông minh và khoa học.'
    },
    title:
      "Gen Z THPT Xuân Đỉnh 'hết mình' cùng phương pháp học tiếng Anh tư duy | Báo Giáo dục và Thời đại Online",
    description:
      "Hơn 1.000 học sinh THPT Xuân Đỉnh tham dự buổi Talkshow 'Gen Z chinh phục ngoại ngữ dễ dàng nhờ phương pháp học tiếng Anh tư duy Linearthinking'.",
    page: {
      url: 'https://giaoducthoidai.vn/gen-z-thpt-xuan-dinh-het-minh-cung-phuong-phap-hoc-tieng-anh-tu-duy-post667686.html'
    }
  },
  {
    cover: {
      url: 'cm8mh7omymkc5071917rthg59',
      alt: 'Buổi Talkshow diễn ra trong không khí sôi nổi dưới sự dẫn dắt của cô Ngọc Anh, giáo viên trẻ nhiệt huyết và tài năng của DOL IELTS Đình Lực.'
    },
    title:
      'Phương pháp tư duy Linearthinking thổi bùng đam mê của Gen Z THPT Lương Thế Vinh | Báo Giáo dục và Thời đại Online',
    description:
      'Buổi Talkshow “Gen Z chinh phục ngoại ngữ dễ dàng nhờ phương pháp học tiếng Anh tư duy Linearthinking” đã được tổ chức tại THPT Lương Thế Vinh.',
    page: {
      url: 'https://giaoducthoidai.vn/phuong-phap-tu-duy-linearthinking-thoi-bung-dam-me-cua-gen-z-thpt-luong-the-vinh-post667687.html'
    }
  },
  {
    cover: {
      url: 'cm8mh7oo9melx0715mf6tqzdm',
      alt: 'Đồng hành cùng các bạn học sinh trường THPT Lý Thái Tổ trong chương trình là diễn giả Đỗ Thị Ngọc Anh - hiện đang là giáo viên tại DOL IELTS Đình Lực với profile đáng ngưỡng mộ: 8.0 IELTS Overall, 9.0 Reading; cử nhân Kinh tế bằng Đỏ tại Nga - Financial University under the Government of the Russian Federation; thạc sỹ Tài chính bằng Distinction - University of the West of England - UWE Bristol; chứng chỉ giảng dạy tiếng Anh quốc tế TESOL; danh hiệu "Đại sứ quốc tế" trường Đại học Tài chính trực thuộc Chính phủ Liên bang Nga.'
    },
    title:
      'Học sinh THPT Lý Thái Tổ sôi động tìm hiểu phương pháp học tiếng Anh tư duy | Báo Giáo dục và Thời đại Online',
    description:
      "DOL IELTS Đình Lực đã tổ chức buổi Talkshow 'Gen Z chinh phục ngoại ngữ dễ dàng nhờ phương pháp học tiếng Anh tư duy Linearthinking' tại THPT Lý Thái Tổ.",
    page: {
      url: 'https://giaoducthoidai.vn/hoc-sinh-thpt-ly-thai-to-soi-dong-tim-hieu-phuong-phap-hoc-tieng-anh-tu-duy-post667688.html'
    }
  },
  {
    cover: {
      url: 'cm8mh7oormkci0719tqcnzkf5',
      alt: 'Hai diễn giả chính góp mặt trong buổi Talkshow lần này là thầy Hoàng Hưng Đức và cô Đỗ Thị Ngọc Anh - giáo viên IELTS tại DOL IELTS Đình Lực, sở hữu một loạt thành tích đáng ngưỡng mộ.'
    },
    title:
      "Hơn 1.000 học sinh THPT Trung Văn 'bùng nổ' cùng phương pháp Linearthinking | Báo Giáo dục và Thời đại Online",
    description:
      "Hơn 1.000 học sinh của Trường THPT Trung Văn đã 'bùng nổ' cùng phương pháp học tiếng Anh tư duy Linearthinking.",
    page: {
      url: 'https://giaoducthoidai.vn/hon-1000-hoc-sinh-thpt-trung-van-bung-no-cung-phuong-phap-linearthinking-post668930.html'
    }
  },
  {
    cover: {
      url: 'cm8mh7omkmeld0715llfzv36b',
      alt: 'Đồng hành cùng các bạn học sinh trong buổi Talkshow lần này là cô Ngọc Anh - giáo viên IELTS tại DOL IELTS Đình Lực. Cô sở hữu bảng thành tích xuất sắc với chứng chỉ giảng dạy tiếng Anh quốc tế TESOL, 8.0 điểm IELTS overall trong đó Reading đạt 9.0 tuyệt đối. Ngoài ra, cô còn là Cử nhân Kinh tế bằng Đỏ tại Nga - Financial University under the Government of the Russian Federation, Thạc sĩ tài chính bằng Distinction - University of the West of England - UWE Bristol.'
    },
    title:
      "GenZ Trường THPT Chuyên Nguyễn Huệ 'cháy' cùng phương pháp Linear Thinking | Báo Giáo dục và Thời đại Online",
    description:
      "GenZ Trường THPT Chuyên Nguyễn Huệ - Hà Nội đã 'cháy hết mình' cùng phương pháp học tiếng Anh tư duy Linear Thinking.",
    page: {
      url: 'https://giaoducthoidai.vn/genz-truong-thpt-chuyen-nguyen-hue-chay-cung-phuong-phap-linearthinking-post668932.html'
    }
  },
  {
    cover: { url: 'cm8mh7om1mkbj0719ofqv6ynq', alt: '' },
    title:
      "Gen Z THPT Đại Mỗ 'cháy' cùng phương pháp học tiếng Anh tư duy Linearthinking | Báo Giáo dục và Thời đại Online",
    description:
      'Chương trình đã diễn ra thành công tốt đẹp và nhận được sự hưởng ứng nồng nhiệt đến từ các thầy cô giáo cũng như toàn thể học sinh trong trường.',
    page: {
      url: 'https://giaoducthoidai.vn/gen-z-thpt-dai-mo-chay-cung-phuong-phap-hoc-tieng-anh-tu-duy-linearthinking-post669514.html'
    }
  },
  {
    cover: {
      url: 'cm8mh7p0dmkdh07197f199k01',
      alt: 'Trong buổi Talkshow, thầy Đức đã giới thiệu tới các bạn học sinh về phương pháp học Tiếng Anh thông minh Linearthinking.'
    },
    title:
      "Học sinh THPT Trần Nhân Tông 'bùng nổ' cùng phương pháp tư duy Linearthinking | Báo Giáo dục và Thời đại Online",
    description:
      "Rất đông các em học sinh tại Trường THPT Trần Nhân Tông đã 'bùng nổ' cùng phương pháp học tiếng Anh tư duy Linear Thinking.",
    page: {
      url: 'https://giaoducthoidai.vn/hoc-sinh-thpt-tran-nhan-tong-bung-no-cung-phuong-phap-tu-duy-linearthinking-post669509.html'
    }
  },
  {
    cover: { url: 'cm8mh7oykmemd0715lvyu2e3p', alt: '' },
    title: 'THPT Thủ Thiêm',
    description:
      'Linearthinking và DOL English “khuấy động” sân trường THPT Thủ Thiêm',
    page: {
      url: 'https://www.facebook.com/dolenglish.ieltsdinhluc/posts/pfbid0217RaYsvuDiLSMTN2xUyhCBiRDFxbewJsKVnLMcmduma61YCVYRXEBLtsnk1XVBZDl'
    }
  },
  {
    cover: { url: 'cm8mh7oywmemk0715gbk0u6xm', alt: '' },
    title: 'THPT Nguyễn Công Trứ',
    description:
      'Chào tuần mới rực rỡ DOL đã mang Linearthinking đến với các bạn học sinh THPT Nguyễn Công Trứ',
    page: {
      url: 'https://www.facebook.com/dolenglish.ieltsdinhluc/posts/pfbid0DNbrqsfSCKJYB9zFzr6ux5nDbfbvvmToxgXfH5ARx7sd7dvegi9pwpnK6HcWQTTYl'
    }
  },
  {
    cover: { url: 'cm8mh7p0fmkdj0719z15jb9k6', alt: '' },
    title: 'School 1',
    description:
      'Ngay lúc này: Các bạn học sinh THPT Lê Quý Đôn hào hứng tìm hiểu phương pháp Linearthinking',
    page: {
      url: 'https://www.facebook.com/dolenglish.ieltsdinhluc/posts/pfbid02vGS5Rjc3jC36smio3hX8GinSGXPiCq8GefuYTtdvha5LhL3UFjjyCsosRHRTXEAl'
    }
  },
  {
    cover: { url: 'cm8mhdunkmfd40715dl48yzab', alt: '' },
    title: 'THPT Thủ Đức',
    description:
      'Chuyến tàu mang phương pháp Linearthinking đã cập bến trường THPT Thủ Đức',
    page: {
      url: 'https://www.facebook.com/dolenglish.ieltsdinhluc/posts/pfbid0pvNxp3JctBNJwaaiWVFXppPVM3EcgmiwyAo5WqJeBPg8RUW94evm8WiwEZ4AiV9cl'
    }
  },
  {
    cover: { url: 'cm8mhdukyml5a0719gh8810s5', alt: '' },
    title: ' Trường THPT Tenloman',
    description:
      'DOL đã mang Linearthinking “hạ cánh” đến với Trường THPT Tenloman',
    page: {
      url: 'https://www.facebook.com/dolenglish.ieltsdinhluc/posts/pfbid02fPfZXt3y8jWbqPuFre5d8kpXtYbm2ffQqDhNjp2cQAYQ1eMFJF19cRRs2iaVgLT2l'
    }
  },
  {
    cover: { url: 'cm8mhdukxmfcj0715bvlgxhc6', alt: '' },
    title: 'THPT Bình Hưng Hoà',
    description:
      'Không khí sôi nổi tại sân trường THPT Bình Hưng Hoà ngay lúc này. Các bạn học sinh đang vô cùng hào hứng tìm hiểu phương pháp học tiếng Anh tư duy của DOL English',
    page: {
      url: 'https://www.facebook.com/story.php?story_fbid=589738006959585&id=100077701710098&rdid=wJjVs3gJjjqn5dza#'
    }
  },
  {
    cover: { url: 'cm8mhdulqml5p0719gg330qc2', alt: '' },
    title: 'THPT Nguyễn Trung Trực',
    description:
      '️Thứ 2 bừng hứng khởi cùng hành trình DOL mang phương pháp Linearthinking "danh bất hư truyền" cập bến trường THPT Nguyễn Trung Trực',
    page: {
      url: 'https://www.facebook.com/dolenglish.ieltsdinhluc/posts/pfbid02GqmPwCdEfDwVtXgBzJDsgQTuoPM8XfsYL5zHCrz7bbek7K6ZXD5ybfyYVhKV3SNUl'
    }
  },
  {
    cover: { url: 'cm8mhdukvml5607191tidh01r', alt: '' },
    title: 'THPT Gia Định',
    description:
      'Khai xuân rực rỡ: Thầy Khoa 9.0 IELTS nhà DOL đã mang Linearthinking đến với các bạn học sinh trường THPT Gia Định',
    page: {
      url: 'https://www.facebook.com/story.php?story_fbid=676354638297921&id=100077701710098&rdid=VtmagiWwAwjP1er2'
    }
  },
  {
    cover: { url: 'cm8mhdum9ml5u0719ah4knz9b', alt: '' },
    title: 'THPT Lý Thường Kiệt',
    description:
      'Chuyến hành trình lan tỏa Linearthinking của DOL đã đến với các bạn học sinh THPT Lý Thường Kiệt',
    page: {
      url: 'https://www.facebook.com/dolenglish.ieltsdinhluc/posts/pfbid0mMeMAPbFUiYzT5Lv4jDaFB3b4hXaznp5aKv5TUgGvKcKt2stUpwHyUV89zT8Tyszl'
    }
  },
  {
    cover: { url: 'cm8mhduksml540719t5qj3apn', alt: '' },
    title: 'THPT An Lạc',
    description: 'DOL đã mang Linearthinking đến với học sinh THPT An Lạc',
    page: {
      url: 'https://www.facebook.com/dolenglish.ieltsdinhluc/posts/pfbid0AJb8sXSnxCFMnXjLny1KpJxJ2Yw1hS76zGbwjF4fd8e1SQLaX3vDQXTaJgmMFW6ml'
    }
  },
  {
    cover: { url: 'cm8mhdulgml5k0719vqjx65re', alt: '' },
    title: ' THPT Dương Văn Thì',
    description:
      'Thắp sáng ngọn lửa tư duy cùng DOL IELTS Đình Lực và phương pháp học tiếng Anh Linearthinking tại THPT Dương Văn Thì',
    page: {
      url: 'https://www.facebook.com/story.php?story_fbid=654914127108639&id=100077701710098&rdid=bCokEo8hIKa31OXM#'
    }
  },
  {
    cover: { url: 'cm8mhdukrmfcd0715lha60ga8', alt: '' },
    title: 'THPT Nguyễn Thị Diệu',
    description:
      'Tiếp lửa đam mê học tiếng Anh bằng phương pháp tư duy Linearthinking đến với học sinh trường THPT Nguyễn Thị Diệu',
    page: {
      url: 'https://www.facebook.com/story.php?story_fbid=593594023240650&id=100077701710098&rdid=DUQJb94w0qnfTSko#'
    }
  },
  {
    cover: { url: 'cm8mhdum9ml5w0719izniy6w8', alt: '' },
    title: ' THPT Nguyễn Thị Minh Khai',
    description:
      'Ngay lúc này: Các bạn học sinh Trường THPT Nguyễn Thị Minh Khai đang say mê tìm hiểu về phương pháp học tiếng Anh tư duy Linearthinking của DOL English',
    page: {
      url: 'https://www.facebook.com/story.php?story_fbid=595168116416574&id=100077701710098&rdid=SaPqAKABxp4nbE8w#'
    }
  },
  {
    cover: { url: 'cm8mhdumamfcz0715ltkwcnxg', alt: '' },
    title: 'THPT Thạnh Lộc',
    description:
      'Tiếp lửa đam mê học tiếng Anh bằng phương pháp tư duy Linearthinking đến với học sinh trường THPT Thạnh Lộc',
    page: {
      url: 'https://www.facebook.com/dolenglish.ieltsdinhluc/posts/pfbid02SvNGer5iP9aypZDbaPfcYGRZY1TUq8dH8zJPQszGGb98RNM8obGw5QEV4hJR96zFl'
    }
  },
  {
    cover: { url: 'cm8mhduktmfcf0715nxqbisf4', alt: '' },
    title: 'THPT Phước Long',
    description:
      'Ngay lúc này:  Các bạn học sinh THPT Phước Long đang say sưa tìm hiểu về phương pháp Linearthinking',
    page: {
      url: 'https://www.facebook.com/story.php?story_fbid=603670378899681&id=100077701710098&rdid=fefh1zl13XjDyU4k#'
    }
  },
  {
    cover: { url: 'cm8mhdullmfcu07155shywtvl', alt: '' },
    title: 'THPT Chuyên Lê Hồng Phong',
    description:
      'Thắp sáng ngọn lửa tư duy cùng DOL English và phương pháp học tiếng Anh Linearthinking tại THPT Chuyên Lê Hồng Phong',
    page: {
      url: 'https://www.facebook.com/story.php?story_fbid=609609534972432&id=100077701710098&rdid=uURhXVX8dYGErkTk#'
    }
  }
].map((item, index) => ({
  ...item,
  id: (index + 1 + firstPageLength).toString()
}));
