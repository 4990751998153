import * as React from 'react';

function IconLearningSpeakingSVG(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75 6C9.75 4.75721 10.7572 3.75 12 3.75C13.2428 3.75 14.25 4.75721 14.25 6V12C14.25 13.2428 13.2428 14.25 12 14.25C10.7572 14.25 9.75 13.2428 9.75 12V6ZM12 2.25C9.92879 2.25 8.25 3.92879 8.25 6V12C8.25 14.0712 9.92879 15.75 12 15.75C14.0712 15.75 15.75 14.0712 15.75 12V6C15.75 3.92879 14.0712 2.25 12 2.25ZM6.75 10C6.75 9.58579 6.41421 9.25 6 9.25C5.58579 9.25 5.25 9.58579 5.25 10V12C5.25 15.4747 7.87481 18.3358 11.25 18.7088V20.25H7.82C7.40579 20.25 7.07 20.5858 7.07 21C7.07 21.4142 7.40579 21.75 7.82 21.75H12H16.18C16.5942 21.75 16.93 21.4142 16.93 21C16.93 20.5858 16.5942 20.25 16.18 20.25H12.75V18.7088C16.1252 18.3358 18.75 15.4747 18.75 12V10C18.75 9.58579 18.4142 9.25 18 9.25C17.5858 9.25 17.25 9.58579 17.25 10V12C17.25 14.8998 14.8998 17.25 12 17.25C9.10021 17.25 6.75 14.8998 6.75 12V10Z"
        fill="currentColor"
      />
    </svg>
  );
}

const IconLearningSpeaking = React.memo(IconLearningSpeakingSVG);
export default IconLearningSpeaking;
