import * as React from 'react';

function IconLearningVocabSVG(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.74776 3.74634H19.0023C19.1406 3.74634 19.2528 3.85845 19.2528 3.99675V15.0013C19.2528 15.1394 19.141 15.2514 19.0029 15.2518L19.0023 15.2518H19.0011H8.74776V3.74634ZM19.0023 2.24634H7.99776H5.99693C4.47768 2.24634 3.24609 3.47793 3.24609 4.99717V18.0026C3.24609 19.521 4.47637 20.7521 5.9945 20.7534L5.99693 20.7534L16.0002 20.7534H16.0011H19.4994H20.0028C20.417 20.7534 20.7528 20.4176 20.7528 20.0034C20.7528 19.5892 20.417 19.2534 20.0028 19.2534H19.7523V16.5834C20.3438 16.3025 20.7528 15.6997 20.7528 15.0013V3.99675C20.7528 3.03003 19.9691 2.24634 19.0023 2.24634ZM4.74609 4.99717V15.5519C5.12135 15.36 5.5465 15.2518 5.99693 15.2518H7.24776V3.74634H5.99693C5.30611 3.74634 4.74609 4.30635 4.74609 4.99717ZM5.99693 19.2534C5.30611 19.2534 4.74609 18.6934 4.74609 18.0026C4.74609 17.3118 5.30611 16.7518 5.99693 16.7518H7.99776H18.2523V19.2534H16.0011H5.99864L5.99693 19.2534ZM10.2786 12.2173L13.2755 6.22351C13.3203 6.13058 13.3846 6.04642 13.4659 5.97769C13.6014 5.86304 13.7739 5.80019 13.9506 5.80005C14.0535 5.79997 14.1579 5.82115 14.2572 5.86573C14.3158 5.89193 14.3712 5.92568 14.4217 5.96643C14.4316 5.97435 14.4412 5.9825 14.4505 5.99086C14.5247 6.05706 14.5837 6.13645 14.6258 6.22353L17.0161 11.0042C17.0207 11.0129 17.0251 11.0217 17.0293 11.0306L17.6227 12.2173C17.8079 12.5878 17.6577 13.0383 17.2873 13.2235C16.9168 13.4088 16.4663 13.2586 16.281 12.8881L15.8881 12.1023H12.0131L11.6202 12.8881C11.435 13.2586 10.9845 13.4088 10.614 13.2235C10.2435 13.0383 10.0933 12.5878 10.2786 12.2173ZM12.7631 10.6023H15.1381L13.9506 8.22729L12.7631 10.6023Z"
        fill="currentColor"
      />
    </svg>
  );
}

const IconLearningVocab = React.memo(IconLearningVocabSVG);
export default IconLearningVocab;
