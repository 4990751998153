import * as React from 'react';
import styled from 'styled-components';
import { colorsV2 } from 'style/colors-v2';
import { Typography } from 'components/DesignSystemV2/Typography';
import { LazyImage } from 'components/LazyImage';
import cl from 'classnames';
import IconLearningReading from './icons/IconLearningReading';
import IconLearningWriting from './icons/IconLearningWriting';
import IconLearningSpeaking from './icons/IconLearningSpeaking';
import IconLearningVocab from './icons/IconLearningVocab';
import IconTwoUsers from 'components/Icon/SVGIcons/IconTwoUsers';
import UserProfile from 'components/UserProfile';
import OverallScore from 'components/OverallScore';
import { toScreen } from 'utils/media-query/responsive.util';

const DATA_LIST = [
  {
    type: 'READING',
    text: 'Reading',
    disadvantages: [
      {
        name: '',
        description:
          'Không đọc hiểu được 1 câu do không đủ từ vựng; hoặc thậm chí lúc có từ vựng, dùng từ điển, dịch từng từ, ghép lại vẫn hiểu sai ý của câu'
      },
      {
        name: '',
        description:
          'Bị đề thi bẫy bằng các từ khoá nhìn giống nhau, điều này đến từ việc không thực sự hiểu liên kết giữa các câu và ý chính của đoạn văn'
      }
    ],
    advantages: [
      {
        name: 'Simplify:',
        description:
          'đơn giản hoá từng câu dựa trên cấu trúc, giúp đọc đến đâu hiểu đến đó và giúp hạn chế sự phụ thuộc vào từ vựng'
      },
      {
        name: 'Read Connection:',
        description:
          'đọc liên kết giữa các câu với nhau để nhanh chóng nắm được ý chính của đoạn, của bài'
      }
    ],
    user: {
      name: 'Mai Khuê',
      school: 'THPT Nam Sài Gòn',
      avatar:
        'https://ap-northeast-1.graphassets.com/A6FEvrzzmSM6yrs3MAVEqz/cm8gqe9y0448y07zqvh4r4kw5'
    },
    overallScore: {
      score: 9.0,
      course: 'IELTS',
      skill: 'Reading'
    }
  },
  {
    type: 'WRITING',
    text: 'Writing',
    disadvantages: [
      {
        name: '',
        description:
          'Viết dịch nên phải "rặn" từng chữ để viết, viết không dễ dàng'
      },
      {
        name: '',
        description:
          'Không biết cách triển khai ý tưởng, Bí ý tưởng, nhất là với đề khó, lạ'
      }
    ],
    advantages: [
      {
        name: 'Lộ trình học Writing từng bước từ dễ đến khó:',
        description:
          'Viết 1 câu đúng → 1 câu hay, dài hơn → 2 câu liên kết → 1 đoạn → 1 bài logic'
      },
      {
        name: 'Tư duy Specify:',
        description:
          'Biến câu hỏi chung chung thành các vấn đề đơn giản, dễ phát triển ý tưởng'
      }
    ],
    user: {
      name: 'Anh Kiệt',
      school: 'THPT Chuyên Lê Quý Đôn',
      avatar:
        'https://ap-northeast-1.graphassets.com/A6FEvrzzmSM6yrs3MAVEqz/cm8gqe9xhbjlt07zp1fhcwrfn'
    },
    overallScore: {
      score: 8.0,
      course: 'IELTS',
      skill: 'Writing'
    }
  },
  {
    type: 'SPEAKING',
    text: 'Speaking',
    disadvantages: [
      {
        name: '',
        description: 'Nói dịch, “rặn” từng chữ để nói'
      },
      {
        name: '',
        description: 'Sợ, xấu hổ, không dám nói'
      },
      {
        name: '',
        description: 'Không nghĩ ra ý tưởng'
      },
      {
        name: '',
        description: 'Có ý tưởng nhưng không biết diễn đạt'
      },
      {
        name: '',
        description:
          'Cảm giác nói được nhưng bài nói không đủ hay, tự nhiên và cao cấp như người bản xứ'
      }
    ],
    advantages: [
      {
        name: 'Lộ trình học Speaking từng bước từ dễ đến khó:',
        description:
          'Luyện phát âm → nói 1 câu đúng → 1 câu hay, dài hơn → 2 câu liên kết → 1 đoạn mạch lạc → nâng cấp bài nói với từ vựng và cấu trúc cao cấp hơn'
      },
      {
        name: 'Tư duy Think in English:',
        description:
          'Nói tự nhiên, trôi chảy mà không cần các từ vựng “đao to búa lớn”'
      },
      {
        name: 'Tư duy Specify:',
        description:
          'Biến câu hỏi chung chung thành vấn đề đơn giản, dễ phát triển ý'
      }
    ],
    user: {
      name: 'Bảo Vy',
      school: 'THPT Chuyên Trần Đại Nghĩa',
      avatar:
        'https://ap-northeast-1.graphassets.com/A6FEvrzzmSM6yrs3MAVEqz/cm8gqe9xu448w07zqb16ryati'
    },
    overallScore: {
      score: 8.5,
      course: 'IELTS',
      skill: 'Speaking'
    }
  },
  {
    type: 'VOCAB_GRAMMAR',
    text: 'Vocab & Grammar',
    disadvantages: [
      {
        name: '',
        description: 'Từ vựng, ngữ pháp quá nhiều, quá khó nhớ'
      },
      {
        name: '',
        description:
          'Không biết cách vận dụng khi đọc, nói và viết hoặc Vận dụng sai tình huống'
      }
    ],
    advantages: [
      {
        name: '',
        description: 'Học ngữ pháp chọn lọc, có tính ứng dụng cao'
      },
      {
        name: '',
        description:
          'Học từ vựng theo mô hình tư duy Linear Model dễ nhớ, dễ dùng cho kỹ năng Nghe - Nói - Đọc -  Viết'
      }
    ],
    user: {
      name: 'Thảo Nguyên',
      school: 'THPT Chuyên Lê Hồng Phong',
      avatar:
        'https://ap-northeast-1.graphassets.com/A6FEvrzzmSM6yrs3MAVEqz/cm8gqe9y1449007zqie54rjat'
    },
    overallScore: {
      score: 8.5,
      course: 'IELTS',
      skill: 'Overall'
    }
  }
];

const disadvantageIconSrc =
  'https://gqefcpylonobj.vcdn.cloud/directus-upload/e2339d53-eb09-47a2-954e-006cc7e3f918.png';
const advantageIconSrc =
  'https://gqefcpylonobj.vcdn.cloud/directus-upload/99415db5-531f-4245-9648-3b315de8a9aa.png';

const Main = styled.div`
  display: grid;
  gap: 24px;
  margin-top: 100px;

  border-top: 1px solid ${colorsV2.neutral20};
`;

const Header = styled.div`
  padding-top: 24px;
  display: grid;
  grid-auto-flow: column;
  align-items: self-start;
`;
const Title = styled.div`
  display: grid;
  grid-template-columns: 20px auto;
  gap: 12px;
`;

const Grid = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  padding: 0;
  margin: 0;

  ${toScreen(1143)} {
    grid-template-columns: repeat(1, 1fr);
  }
`;
const CardInfoItem = styled.li`
  background: ${colorsV2.white100};
  padding: 24px;
  margin: 0;
  border-radius: 24px;
  box-shadow: 0px 2px 6px 0px #30407812, 0px 0px 2px 0px #3042780f;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  flex: 1;
`;
const Parent = styled.div`
  position: relative;
  display: grid;
  gap: 12px;
  height: fit-content;
`;
const Child = styled.div`
  .icon-image {
    position: absolute;
    margin-top: 2px;
    img {
      width: 20px;
    }
  }
  .text {
    display: inline;
    margin-left: 25px;
  }
`;

const StudentWrapper = styled.div`
  display: grid;
  gap: 16px;
`;

const RoleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 12px;
  color: ${colorsV2.primary100};
  border-radius: 8px;
  background-color: ${colorsV2.primary10};
`;

const ProfileWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-content: space-between;

  ${toScreen(457)} {
    grid-template-columns: 1fr;
    gap: 12px;
  }

  .overall-score {
    ${toScreen(457)} {
      justify-self: start;
    }
  }
`;

const LinearthinkingApplyToSkills = () => {
  const getIcon = React.useCallback(dataType => {
    const sharedProps = {
      width: 32,
      height: 32,
      color: colorsV2.neutral180
    };

    switch (dataType) {
      case 'READING':
        return <IconLearningReading {...sharedProps} />;
      case 'WRITING':
        return <IconLearningWriting {...sharedProps} />;
      case 'SPEAKING':
        return <IconLearningSpeaking {...sharedProps} />;
      case 'VOCAB_GRAMMAR':
        return <IconLearningVocab {...sharedProps} />;
      default:
        break;
    }
  }, []);

  const renderChild = (item, iconSrc) => (
    <Child key={item.cIdx}>
      <LazyImage className="icon-image" src={iconSrc} alt="test" />
      <Typography
        className="text"
        variant="regular/14-20"
        color={colorsV2.neutral180}
      >
        {item.name && <strong>{item.name} </strong>}
        {item.description}
      </Typography>
    </Child>
  );

  return (
    <Main>
      <Header>
        <Title>
          <LazyImage
            src="https://gqefcpylonobj.vcdn.cloud/directus-upload/9895d71b-ea2b-4126-9c15-679c7b7f7438.png"
            alt="linearthinking-to-skills"
          />
          <Typography variant="semi-bold/14-20" color={colorsV2.gray120}>
            Ứng dụng Linearthinking vào cách học các kĩ năng
          </Typography>
        </Title>
      </Header>
      <Grid>
        {DATA_LIST.map((eachData, idx) => (
          <CardInfoItem
            key={idx}
            className={cl('keen-slider__slide', 'card-info')}
          >
            <div>
              {getIcon(eachData.type)}
              <Typography variant="semi-bold/20-28" color={colorsV2.neutral200}>
                {eachData.text}
              </Typography>
            </div>

            <List>
              <Parent>
                {eachData.disadvantages.map((eachChild, cIdx) =>
                  renderChild({ ...eachChild, cIdx }, disadvantageIconSrc)
                )}
              </Parent>
              <Parent>
                {eachData.advantages.map((eachChild, cIdx) =>
                  renderChild({ ...eachChild, cIdx }, advantageIconSrc)
                )}
              </Parent>
            </List>
            <StudentWrapper>
              <RoleWrapper>
                <IconTwoUsers />
                <Typography variant="regular/16-20" color="currentColor">
                  Học viên tại DOL
                </Typography>
              </RoleWrapper>
              <ProfileWrapper>
                <UserProfile {...eachData.user} />
                <OverallScore
                  className="overall-score"
                  {...eachData.overallScore}
                />
              </ProfileWrapper>
            </StudentWrapper>
          </CardInfoItem>
        ))}
      </Grid>
    </Main>
  );
};

export default LinearthinkingApplyToSkills;
