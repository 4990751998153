import React from 'react';
import styled from 'styled-components';
import { colorsV2 } from 'style/colors-v2';
import {
  IconArrowChevronLeftArrowLeft,
  IconArrowChevronLeftArrowRight
} from 'components/Icon/SVGIcons';

const Button = styled.button`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colorsV2.primary10};
  border-radius: 40px;
  cursor: pointer;
  border: none;
  transition: background 0.3s ease;

  &:hover:not(:disabled) {
    background: ${colorsV2.primary20};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    filter: grayscale(100%);
  }
`;

const ArrowButton = ({ direction = 'left', ...props }) => {
  return (
    <Button
      title={direction === 'left' ? 'Previous' : 'Next'}
      aria-label={direction === 'left' ? 'Previous' : 'Next'}
      aria-disabled={props.disabled}
      {...props}
    >
      {direction === 'left' ? (
        <IconArrowChevronLeftArrowLeft
          width={24}
          height={24}
          color={colorsV2.primary100}
        />
      ) : (
        <IconArrowChevronLeftArrowRight
          width={24}
          height={24}
          color={colorsV2.primary100}
        />
      )}
    </Button>
  );
};

export default ArrowButton;
