import BookGallery from 'components/BookGallery';
import { Typography } from 'components/DesignSystemV2/Typography';
import LinkButton from 'components/LinkButton';
import { ResponsiveContainer } from 'components/ResponsiveContainer';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { IconChervonRight } from 'shared/CourseLanding/icons/IconChervonRight';
import { colorsV2 } from 'style/colors-v2';
import styled from 'styled-components';
import { toScreen } from 'utils/media-query/responsive.util';

const Wrapper = styled.div`
  padding: 100px 0;
  background: linear-gradient(
    180deg,
    ${colorsV2.white100} 0%,
    ${colorsV2.blue10} 100%
  );

  ${toScreen(775)} {
    padding: 64px 0;
  }
`;

const Content = styled.div`
  display: flex;
  gap: 64px;

  ${toScreen(1143)} {
    flex-direction: column;
    max-width: 712px;
  }
`;

const Left = styled.div`
  flex: 1;
`;

const Right = styled.div`
  flex: 1;
`;

const ActionButton = styled(LinkButton)`
  color: ${colorsV2.blue100};
  margin-top: 26px;

  &:hover {
    background-color: transparent;
    color: ${colorsV2.blue80};
  }
`;

const LinearThinkingBooks = () => {
  const data = useStaticQuery(graphql`
    {
      gcms {
        jsonContanier(where: { name: "All_Dol_Books" }) {
          content
        }
      }
    }
  `);

  // Find first IELTS book
  const bookImages = data.gcms.jsonContanier.content.find(
    item => item.groupTitle === 'DOL IELTS'
  );

  return (
    <Wrapper>
      <ResponsiveContainer>
        <Content>
          <Left>
            <Typography
              tabletVariant="medium/32-40"
              variant="medium/24-32"
              color={colorsV2.black100}
              v3
            >
              Lần đầu tiên có phương pháp học Tiếng Anh được phát triển có hệ
              thống khoa học và được kèm theo bộ sách được xuất bản đi kèm, từ
              level Pre IELTS đến 8.0+ IELTS
            </Typography>
            <ActionButton
              href="/book"
              icon={<IconChervonRight width={18} height={18} />}
            >
              Xem thêm về sách
            </ActionButton>
          </Left>
          <Right>
            <BookGallery bookImages={bookImages?.images} />
          </Right>
        </Content>
      </ResponsiveContainer>
    </Wrapper>
  );
};

export default LinearThinkingBooks;
