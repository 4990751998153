import React, { useEffect, useState } from 'react';
import ReactImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import styled from 'styled-components';
import { colorsV2 } from 'style/colors-v2';
import { DemoBookIcon } from 'components/Icon/SVGIcons';
import { Typography } from 'components/DesignSystemV2/Typography';
import Lightbox from 'react-image-lightbox';

const bookThumbnails = [
  {
    original: 'https://media.graphassets.com/WP5uQRfZTkOEL2dYAmHv',
    thumbnail: 'https://media.graphassets.com/ZGKjZC4qS4S0FfNTYzw0'
  },
  {
    original: 'https://media.graphassets.com/2JoY5Wd6TUGoDj9np0rg',
    thumbnail: 'https://media.graphassets.com/TqkpLTehSOOEjte4xwCX'
  },
  {
    original: 'https://media.graphassets.com/4WUWW3X3Qcu4I1DGIwQd',
    thumbnail: 'https://media.graphassets.com/iAKiC0XTUeYpkFiFwyAJ'
  },
  {
    original: 'https://media.graphassets.com/IYIAUB4ETySmARV1P9vf',
    thumbnail: 'https://media.graphassets.com/eBCotY8lQb2Y0VQEB7xQ'
  }
];

const Wrapper = styled.div`
  .image-gallery-slide-wrapper {
    margin-bottom: 24px;
    border-radius: 16px;
    overflow: hidden;
  }

  .image-gallery-thumbnail {
    border: 1px solid ${colorsV2.white100};
    border-radius: 8px;
    overflow: hidden;

    & + .image-gallery-thumbnail {
      margin-left: 12px;
    }

    &.active {
      box-shadow: 0 0 0 2px ${colorsV2.primary60}, 0 2px 6px 0 #3042780f,
        0 4px 4px 0 #00000040;
    }
  }
`;

const ImageWrapper = styled.div`
  aspect-ratio: 508 / 352;
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`;

const ThumbnailWrapper = styled.div`
  aspect-ratio: 96 / 64;
  position: relative;
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`;

const DemoButton = styled.button`
  display: flex;
  position: absolute;
  bottom: 16px;
  right: 16px;
  align-items: center;
  gap: 12px;
  padding: 8px 12px 8px 8px;
  border-radius: 12px;
  background-color: ${colorsV2.white80};
  backdrop-filter: blur(16px);
  color: ${colorsV2.blue100};
  z-index: 1;
  border: none;
  box-shadow: inset 0 0 0 1px ${colorsV2.white20};
  cursor: pointer;
`;

const BookGallery = ({ bookImages = [] }) => {
  const [isOpen, setOpen] = useState(false);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    // Reset index when lightbox is closed
    if (!isOpen) {
      setIndex(0);
    }
  }, [isOpen]);

  function renderItem({ original }) {
    return (
      <ImageWrapper>
        <Image loading="lazy" src={original} alt="book" />
      </ImageWrapper>
    );
  }

  function renderThumbInner({ thumbnail }) {
    return (
      <ThumbnailWrapper>
        <Thumbnail loading="lazy" src={thumbnail} alt="book" />
      </ThumbnailWrapper>
    );
  }

  function renderCustomControls() {
    if (!bookImages.length) return null;
    return (
      <DemoButton onClick={() => setOpen(true)}>
        <DemoBookIcon color="currentColor" />
        <Typography variant="regular/16-24" color="currentColor" type="span">
          Xem demo sách
        </Typography>
      </DemoButton>
    );
  }

  return (
    <Wrapper>
      <ReactImageGallery
        lazyLoad
        infinite
        autoPlay
        showBullets={false}
        showPlayButton={false}
        showFullscreenButton={false}
        showNav={false}
        items={bookThumbnails}
        renderItem={renderItem}
        renderThumbInner={renderThumbInner}
        renderCustomControls={renderCustomControls}
      />
      {isOpen && (
        <Lightbox
          mainSrc={bookImages[index]}
          nextSrc={bookImages[index + 1]}
          prevSrc={bookImages[index - 1]}
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() => setIndex(index - 1)}
          onMoveNextRequest={() => setIndex(index + 1)}
          reactModalStyle={{
            overlay: {
              zIndex: 99999 // On top of Zalo & Messenger buttons
            }
          }}
        />
      )}
    </Wrapper>
  );
};

export default BookGallery;
