import { Typography } from 'components/DesignSystemV2/Typography';
import { LazyImage } from 'components/LazyImage';
import React from 'react';
import { colorsV2 } from 'style/colors-v2';
import styled from 'styled-components';

const ImageContainer = styled.div`
  position: relative;
  width: 120px;
  aspect-ratio: 120 / 92;
  flex-shrink: 0;
  border: 2px solid ${colorsV2.white100};
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0px 8px 20px 0px rgba(27, 43, 67, 0.1),
    0px 3px 6px 0px rgba(27, 43, 67, 0.1), 0px 0px 1px 0px rgba(27, 43, 67, 0.1);

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
`;

const ItemWrapper = styled.li`
  display: flex;
  gap: 28px;
  align-items: flex-start;

  &:hover {
    ${ImageContainer} {
      transform: scale(1.15);
    }
  }
`;

const StyledLink = styled.a`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  color: ${colorsV2.neutral200};
  &:hover {
    text-decoration: underline;
  }
`;

const Title = styled(Typography)``;

const Desc = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const LinearThinkingSharingListItem = ({ cover, description, title, page }) => {
  // Assets from DOL English use media.dolenglish.vn/PUBLIC/MEDIA/[File Name]
  // where as assets from Hygraph use handle
  let imageProps = {};
  if (cover.url.startsWith('https://')) {
    imageProps = {
      src: cover.url,
      alt: cover.alt
    };
  } else {
    imageProps = {
      handle: cover.url,
      alt: cover.alt
    };
  }
  return (
    <ItemWrapper>
      <ImageContainer>
        <LazyImage {...imageProps} />
      </ImageContainer>
      <Content>
        <StyledLink href={page.url} target="_blank" rel="noopener noreferrer">
          <Title variant="bold/14-20" type="span">
            {title}
          </Title>
        </StyledLink>
        <Desc variant="regular/16-24" color={colorsV2.neutral180}>
          {description}
        </Desc>
      </Content>
    </ItemWrapper>
  );
};

export default LinearThinkingSharingListItem;
