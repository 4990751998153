import * as React from 'react';
import styled from 'styled-components';
import IconCheck2 from './icons/IconCheck2';
import { colorsV2 } from 'style/colors-v2';
import { Typography } from 'components/DesignSystemV2/Typography';
import { LazyImage } from 'components/LazyImage';
import IconEssentialHistoryList from './icons/IconEssentialHistoryList';
import { toScreen } from 'utils/media-query/responsive.util';
import { VideoThumbnail } from 'components/VideoThumbnail';
import { useModalController } from 'shared/IeltsJunior/hooks/useModalHook';
import Modal from 'shared/CourseLanding/Modal';
import { VideoPlayer } from './YoutubeVideoCourses';

const DATA = [
  'Hệ phương pháp tiếng Anh tư duy, logic được tạo ra bởi đội ngũ giáo viên người Việt ở DOL IELTS Đình Lực, giải quyết cụ thể những vấn đề trong việc học tiếng Anh của người Việt.',
  'Được áp dụng vào tất cả các kỹ năng Writing, Speaking, Listening, Reading cũng như việc học Grammar và Vocab',
  'Khác với khái niệm Linear thinking (Tư Duy Tuyến Tính) hay nghe. Linearthinking là một hệ phương pháp chứ không chỉ đơn thuần là phát triển ý theo lối suy nghĩ tuyến tính, đường thẳng.'
];
const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 16px;
`;

const Item = ({ text }) => {
  return (
    <Container>
      <IconCheck2 width={20} height={20} color={colorsV2.green100} />
      <Typography variant="regular/16-24" color={colorsV2.gray120}>
        {text}
      </Typography>
    </Container>
  );
};

const Main = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: auto;
  gap: 48px;
  overflow-x: hidden;

  ${toScreen(1143)} {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 96px;
  }

  ${toScreen(775)} {
    gap: 48px;
  }
`;

const HeaderInfo = styled.div`
  display: grid;
  gap: 16px;

  margin-bottom: 48px;
`;

const List = styled.div`
  display: grid;
  gap: 24px;
`;

const Left = styled.div``;

const VideoWrapper = styled.div`
  display: grid;
  grid-template-columns: 120px 1fr;
  gap: 16px;
  margin-top: 64px;
  align-items: center;
`;

const Right = styled.figure`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 12px;
  margin: 0;
  padding: 0;

  .us-cert {
    position: absolute;
    width: 260px;
    top: -1%;
    left: -3%;
    z-index: -1;
  }

  .dol-cert {
    position: absolute;
    width: 220px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -2;
  }

  .india-cert {
    position: absolute;
    width: 226px;
    top: 46%;
    left: 54%;
    z-index: -3;
  }

  .us-icon {
    background: white;
    position: absolute;
    top: 48%;
    left: 6%;
    z-index: 5;
  }

  .vietnam-icon {
    background: white;
    position: absolute;
    top: 21%;
    left: 54%;
    z-index: 5;
    transform: rotateZ(8deg);
  }

  .india-icon {
    background: white;
    position: absolute;
    top: 76%;
    left: 48%;
    transform: rotate(7.46deg);
    z-index: 5;
  }
`;

const CertificateInfo = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  gap: 24px;
  width: 330px;
  margin: 0 auto;
`;

const CertificateWrapper = styled.div`
  margin: 0 auto;
`;

const LinearthinkingCertificatesV3 = () => {
  const { isOpen, openModal, closeModal } = useModalController();
  const [videoUrl, setVideoUrl] = React.useState('');

  return (
    <>
      <Main className="linear-thinking-certificates">
        <Left>
          <HeaderInfo>
            <Typography variant="medium/16-24" color={colorsV2.gray120}>
              Quyền sở hữu trí tuệ bởi DOL
            </Typography>
            <Typography
              variant="bold/24-32"
              tabletVariant="bold/32-40"
              desktopVariant="bold/40-48"
              color={colorsV2.black100}
              v3
            >
              Linearthinking - phương pháp độc quyền được chứng nhận Sở Hữu Trí
              Tuệ ở nhiều quốc gia
            </Typography>
          </HeaderInfo>
          <List>
            {DATA.map((eachData, idx) => (
              <Item key={idx} text={eachData} />
            ))}
          </List>
          <VideoWrapper>
            <VideoThumbnail
              poster="https://ap-northeast-1.graphassets.com/A6FEvrzzmSM6yrs3MAVEqz/cm8h36vfb3pru0714vi9q67q5"
              width={120}
              iconSize={32}
              onClick={() => {
                // Temporarily hardcode video url
                setVideoUrl('https://www.youtube.com/embed/TyBKJeRHWBo');
                openModal();
              }}
            />
            <Typography variant="regular/16-24" color={colorsV2.gray120}>
              Cô Quỳnh, IELTS 9.0+ giám đốc học thuật tại DOL giới thiệu về
              Linearthinking
            </Typography>
          </VideoWrapper>
        </Left>
        <Right>
          <CertificateWrapper>
            <LazyImage
              src="https://media.graphassets.com/bi6jrQ4Sfe7J2fVspG1M"
              alt="linear-thinking-certificates"
            />
          </CertificateWrapper>
          <CertificateInfo>
            <IconEssentialHistoryList
              color={colorsV2.neutral100}
              width={20}
              height={20}
            />
            <figcaption>
              <Typography
                variant="medium/16-24"
                color={colorsV2.gray120}
                type="span"
              >
                Giấy chứng nhận sở hữu trí tuệ của Việt Nam, Mỹ, Ấn Độ,...
              </Typography>
            </figcaption>
          </CertificateInfo>
        </Right>
      </Main>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => {
          setVideoUrl('');
          closeModal();
        }}
      >
        <VideoPlayer videoUrl={videoUrl} autoPlay />
      </Modal>
    </>
  );
};

export default LinearthinkingCertificatesV3;
