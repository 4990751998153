import * as React from 'react';
import styled from 'styled-components';
import { colorsV2 } from 'style/colors-v2';
import { Typography } from 'components/DesignSystemV2/Typography';
import { useKeenSlider } from 'keen-slider/react';
import cl from 'classnames';

const LIST_DATA = [
  {
    name: 'Minh Anh',
    score: '8.0 IELTS, 1520 SAT',
    school: 'Phổ thông Năng khiếu',
    quote:
      'Mình thấy học sinh Phổ thông Năng khiếu học ở DOL rất nhiều và điểm cũng rất xuất sắc.'
  },
  {
    name: 'Minh Khôi',
    score: '8.0 IELTS',
    school: 'Phổ thông Năng khiếu',
    quote: 'IELTS học ở DOL là tưởng không dễ nhưng mà là dễ không tưởng luôn.'
  },
  {
    name: 'Minh Đạt',
    score: '8.0 IELTS',
    school: 'THPT Bình Chánh',
    quote: 'Nhà em có mẹ, chị hai em và em là "3 đời" học ở DOL.'
  },
  {
    name: 'Mỹ Phương',
    score: '8.0 IELTS',
    school: 'THPT chuyên Lê Hồng Phong',
    quote:
      'Linearthinking không phải là phương pháp "mì ăn liền" mà là cách để mình tiếp cận đúng với bản chất của ngoại ngữ.'
  },
  {
    name: 'Đức Cường',
    score: '1530 SAT',
    school: 'Phổ thông Năng khiếu',
    quote:
      'Linearthinking giúp mình xác định được thẳng câu hỏi ở phần Toán và nhờ đó mình có thể làm trong khoảng chỉ 2, 3 giây gì đó.'
  }
];

const Main = styled.div`
  display: grid;
  gap: 24px;

  .quote-wrapper {
    width: 100%;
  }
`;

const Content = styled.div`
  max-width: 726px;
  display: grid;
  gap: 32px;

  text-align: center;
  margin: auto;
`;
const TeacherInfo = styled.div``;

const Dots = styled.div`
  display: grid;
  gap: 8px;
  grid-auto-flow: column;
  justify-content: center;
`;
const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${colorsV2.neutral20};
  cursor: pointer;

  &.actived {
    background: ${colorsV2.primary100};
  }
`;

const LinearthinkingQuote = () => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [sliderRef, slider] = useKeenSlider({
    slidesPerView: 1,
    spacing: 8,
    loop: true,
    duration: 2000,
    mode: 'free-snap'
  });

  React.useEffect(() => {
    const interval = setInterval(() => {
      slider?.next();
      setSelectedIndex(pre => {
        if (pre >= LIST_DATA.length - 1) {
          return 0;
        }
        return pre + 1;
      });
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [slider]);

  return (
    <Main className="linear-thinking-book">
      <div ref={sliderRef} className="keen-slider">
        {LIST_DATA.map(({ name, score, school, quote }, idx) => (
          <div key={idx} className={cl('keen-slider__slide', 'quote-wrapper')}>
            <Content>
              <Typography
                variant="semi-bold/24-32"
                tabletVariant="semi-bold/28-36"
                color={colorsV2.neutral180}
                v3
              >
                {quote}
              </Typography>
              <TeacherInfo>
                <Typography variant="bold/16-24" color={colorsV2.neutral180}>
                  {name} - {school}
                </Typography>
                <Typography variant="regular/14-20" color={colorsV2.neutral100}>
                  {score}
                </Typography>
              </TeacherInfo>
            </Content>
          </div>
        ))}
      </div>
      <Dots>
        {LIST_DATA.map((_, idx) => (
          <Dot
            key={idx}
            className={cl({ actived: idx === selectedIndex })}
            onClick={() => {
              if (slider) {
                slider?.next();
                setSelectedIndex(idx);
              }
            }}
          />
        ))}
      </Dots>
    </Main>
  );
};

export default LinearthinkingQuote;
