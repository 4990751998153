import * as React from 'react';

function IconTeacherOverviewGraduateHatSVG(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.35929 3.4756C11.0021 2.56396 12.9979 2.56396 14.6407 3.4756L20.815 6.90574C20.815 6.90573 20.815 6.90575 20.815 6.90574C22.5988 7.89662 22.5988 10.4613 20.815 11.4522C20.815 11.4522 20.815 11.4522 20.815 11.4522L18.7163 12.6181V16.124C18.7163 17.3086 18.1241 18.4162 17.1363 19.0727M17.1363 19.0727L15.5293 20.1417C15.5293 20.1417 15.5294 20.1417 15.5293 20.1417C13.3916 21.5639 10.6083 21.5638 8.46979 20.1418L6.86267 19.0727C5.87653 18.4166 5.28265 17.3103 5.28265 16.124V12.6175L3.18503 11.4522C3.18502 11.4522 3.18505 11.4522 3.18503 11.4522C1.40124 10.4613 1.4012 7.89664 3.18499 6.90576C3.18498 6.90577 3.18501 6.90575 3.18499 6.90576L9.35929 3.4756M6.36368 11.5975C6.34456 11.5856 6.32484 11.5747 6.30457 11.5646L3.873 10.2138C3.06085 9.76263 3.06081 8.59533 3.87296 8.1442L10.0467 4.71434C10.0466 4.7144 10.0468 4.71429 10.0467 4.71434C11.2618 4.0402 12.7379 4.04003 13.953 4.71418C13.9529 4.71413 13.9531 4.71423 13.953 4.71418L20.127 8.14418C20.9391 8.59531 20.9392 9.76261 20.127 10.2137L17.7025 11.5607C17.6767 11.5731 17.6517 11.587 17.6277 11.6022L13.9533 13.6436C13.9532 13.6437 13.9534 13.6435 13.9533 13.6436C12.7381 14.3178 11.2609 14.3178 10.047 13.6438C10.047 13.6437 10.047 13.6438 10.047 13.6438L6.36368 11.5975ZM6.69935 13.4046V16.124C6.69935 16.8356 7.05551 17.4994 7.64733 17.8932C7.6473 17.8932 7.64735 17.8932 7.64733 17.8932L9.2542 18.9621C10.9177 20.0682 13.0824 20.0681 14.7446 18.9622L16.3517 17.8932C16.9437 17.4997 17.2996 16.8352 17.2996 16.124V13.4052L14.641 14.8822C12.9983 15.7938 11.0011 15.7941 9.3591 14.8822L6.69935 13.4046Z"
        fill="currentColor"
      />
    </svg>
  );
}

const IconTeacherOverviewGraduateHat = React.memo(
  IconTeacherOverviewGraduateHatSVG
);
export default IconTeacherOverviewGraduateHat;
