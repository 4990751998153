import React from 'react';
import styled from 'styled-components';
import LinearThinkingSharingListItem from './LinearThinkingSharingListItem';
import { toScreen } from 'utils/media-query/responsive.util';
import OutlinedButton from 'components/OutlinedButton';

const Grid = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 40px;
  row-gap: 24px;
  margin: 0;
  padding: 0;
  position: relative;

  ${toScreen(775)} {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

const LoadMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const LinearThinkingSharingList = ({
  sharingEvents: { content },
  onLoadMoreClick,
  hasMore
}) => {
  return (
    <>
      <Grid>
        {content.map(event => (
          <LinearThinkingSharingListItem key={event.id} {...event} />
        ))}
      </Grid>

      {hasMore && (
        <LoadMoreWrapper>
          <OutlinedButton onClick={onLoadMoreClick}>
            Hiển thị thêm
          </OutlinedButton>
        </LoadMoreWrapper>
      )}
    </>
  );
};

export default LinearThinkingSharingList;
