import React from 'react';
import Marquee from 'react-fast-marquee';

const LinearThinkingNewsSlide = ({
  speed = 50,
  pauseOnHover = true,
  children
}) => {
  return (
    <Marquee speed={speed} pauseOnHover={pauseOnHover}>
      {children}
    </Marquee>
  );
};

export default LinearThinkingNewsSlide;
