import React from 'react';
import styled from 'styled-components';
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';

const ScoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 12px;
  background-color: ${colorsV2.white100};
  border: 1px solid ${colorsV2.neutral20};
  border-radius: 12px;
`;

const OverallScore = ({ score = 0, course, skill, className }) => {
  return (
    <ScoreWrapper className={className}>
      <Typography variant="semi-bold/28-36" color={colorsV2.primary100}>
        {score.toFixed(1)}
      </Typography>
      <Typography variant="regular/14-16" color={colorsV2.neutral180}>
        {course} {skill}
      </Typography>
    </ScoreWrapper>
  );
};

export default OverallScore;
