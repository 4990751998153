import React from 'react';
import { LazyImage } from 'components/LazyImage';
import styled from 'styled-components';
import { colorsV2 } from 'style/colors-v2';
import { Typography } from 'components/DesignSystemV2/Typography';

const Wrapper = styled.div`
  max-width: 300px;
  width: 100%;
  overflow: hidden;
  text-align: left;
  margin-right: 24px;
`;

const ImageWrapper = styled.div`
  margin-bottom: 8px;
  img {
    max-width: 100%;
    height: 40px;
  }
`;

const Divider = styled.hr`
  margin: 24px 0;
  border: 0;
  border-top: 1px solid ${colorsV2.neutral20};
`;

const NewsWrapper = styled.a`
  display: flex;
  align-items: start;
  gap: 12px;
  color: ${colorsV2.neutral120};
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: text-decoration-color 0.3s ease;

  &:hover {
    text-decoration-color: ${colorsV2.neutral120};
    img {
      filter: grayscale(0%);
    }
  }
`;

const ThumbnailWrapper = styled.div`
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  position: relative;
  border-radius: 8px;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: grayscale(100%);
    transition: filter 0.3s ease;
  }
`;

const LinearThinkingNewsSlideItem = ({
  logo = '',
  logoName = '',
  thumbnail = '',
  newsTitle = '',
  actionUrl = ''
}) => {
  return (
    <Wrapper>
      <ImageWrapper>
        <LazyImage handle={logo} alt={logoName} />
      </ImageWrapper>
      <Typography variant="medium/14-20" color={colorsV2.neutral120}>
        {logoName}
      </Typography>
      <Divider />

      <NewsWrapper href={actionUrl} target="_blank" rel="noopener noreferrer">
        <ThumbnailWrapper>
          <LazyImage handle={thumbnail} alt={newsTitle} />
        </ThumbnailWrapper>
        <Typography
          variant="medium/14-20"
          color={colorsV2.neutral120}
          type="span"
        >
          {newsTitle}
        </Typography>
      </NewsWrapper>
    </Wrapper>
  );
};

export default LinearThinkingNewsSlideItem;
