import * as React from 'react';
import styled from 'styled-components/macro';

/* eslint-disable react/prop-types */
import { Typography } from 'components/DesignSystemV2/Typography';
import { withHomePageFullScreen } from 'components/withHomePageFullScreen';
import { colorsV2 } from 'style/colors-v2';
import { TagLabel } from 'shared/TagLabel/TagLabel';
import { flexGap } from 'style/css-style';
import { fromScreen, toScreen } from 'utils/media-query/responsive.util';
import { motion, useViewportScroll, useTransform } from 'framer-motion';

const images = [
  'https://media.graphassets.com/34KucEmiTJWWc5DiMlxz',
  'https://media.graphassets.com/QW6dEmWQTbiMcAyDu1IA',
  'https://media.graphassets.com/QkmdW4HT4GFOK4JiTIsb',
  'https://media.graphassets.com/CDNheYN6QnaGewzJvBiT',
  'https://media.graphassets.com/2XhaV1dWToWkjWdBb7SB'
];

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
  overflow-x: hidden;
`;

const HeaderTitle = styled.div`
  padding-top: 48px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${flexGap(24, 'column')};
  margin: auto;
  text-align: center;
  .tag-header {
    width: fit-content;
    border-radius: 38px;
  }
  .info {
    border-radius: 12px;
  }
  ${fromScreen(776)} {
    width: 712px;
  }
  .red {
    color: ${colorsV2.red100};
  }
`;

const ImagesWrapper = styled.div`
  display: flex;
  height: 600px;
  overflow: hidden;
  gap: 30px;
  position: relative;

  ${toScreen(1143)} {
    min-width: 1366px;
  }

  ${toScreen(775)} {
    min-width: 776px;
    height: 340px;
    gap: 16px;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(
      180deg,
      rgba(255, 249, 241, 0) 0%,
      #fff9f1 100%
    );
    z-index: 1;
  }
`;

const ImageColumn = styled(motion.div)`
  &:nth-child(odd) {
    padding-top: 60px;
    padding-bottom: 60px;

    ${toScreen(775)} {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
`;

const itemVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  transition: {
    duration: 0.5,
    ease: 'easeInOut'
  }
};

const Header = () => {
  const containerRef = React.useRef(null);
  const { scrollY } = useViewportScroll();

  const oddColumnsY = useTransform(scrollY, [0, 1000], [0, -100]);
  const evenColumnsY = useTransform(scrollY, [0, 1000], [0, 100]);

  return (
    <Wrapper>
      <HeaderTitle>
        <TagLabel
          className="tag-header"
          text="Linearthinking"
          color="primary"
        />
        <Typography
          variant="bold/32-40"
          tabletVariant="bold/40-48"
          desktopVariant="bold/48-57"
          color={colorsV2.black100}
          v3
        >
          <span className="red">Linearthinking</span> - hệ phương pháp học Tiếng
          Anh Tư Duy dành cho người Việt
        </Typography>
        <Typography variant="regular/16-24" color={colorsV2.gray120}>
          Lần đầu tiên trong lịch sử học Tiếng Anh ở Việt Nam, thực sự có một
          phương pháp đột phá sinh ra để giải quyết các vấn đề cố hữu của người
          Việt khi học Tiếng Anh, giúp việc học Tiếng Anh của học sinh Việt Nam
          trở nên tư duy, logic, hiệu quả và ứng dụng hơn.
        </Typography>
      </HeaderTitle>
      <ImagesWrapper ref={containerRef}>
        {images.map((url, index) => (
          <ImageColumn
            key={index}
            style={{ y: index % 2 === 0 ? oddColumnsY : evenColumnsY }}
            variants={itemVariants}
            initial="hidden"
            animate="visible"
            transition={{
              duration: 0.5,
              ease: 'easeInOut',
              delay: index * 0.15
            }}
          >
            <img src={url} alt="background" />
          </ImageColumn>
        ))}
      </ImagesWrapper>
    </Wrapper>
  );
};

export const LinearthinkingHeaderV3 = withHomePageFullScreen(
  () => {
    return <Header />;
  },
  {
    style: {
      backgroundColor: colorsV2.paper
    }
  }
);
