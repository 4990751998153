/* eslint-disable react/prop-types */
import * as React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { colorsV2 } from 'style/colors-v2';
import { Typography } from '../../components/DesignSystemV2/Typography';
import Modal from '../CourseLanding/Modal';
import { LazyImage } from 'components/LazyImage';
import { IconCourseSocialYoutube } from 'components/Icon/SVGIcons/IconCourseSocialYoutube';
import { VideoPlayer } from './YoutubeVideoCourses';
import { toScreen } from 'utils/media-query/responsive.util';
import { VideoThumbnail } from 'components/VideoThumbnail';

const MainCourses = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 736px) minmax(0, 320px);
  gap: 24px;

  ${toScreen(1143)} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const CardOtherVideoMain = styled.div`
  display: grid;
  gap: 12px;
  padding: 16px;
  border: 1px solid ${colorsV2.neutral20};
  border-radius: 16px;
  contain: size;
  overflow-x: hidden;

  ${toScreen(1143)} {
    contain: none;
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 8px;
    background: ${colorsV2.neutral20};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: ${colorsV2.primary80};
  }

  .link-youtube {
    button {
      border-radius: 12px;
      width: 100%;
    }
  }
`;
const OtherVideoMainItem = styled.div`
  display: grid;
  grid-template-columns: 128px auto;
  gap: 16px;
  position: relative;

  .other-video-main {
    img {
      border-radius: 8px;
    }
  }
`;
const VideoInfo = styled.div`
  display: grid;
  gap: 8px;
  height: fit-content;
  .other-video-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
const ViewVideo = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 4px;
  width: fit-content;
`;

const ViewOnYoutube = styled.a`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const MainVideoCourses = ({ videos, onPlayVideo }) => {
  const mainVideo = videos[0];
  const otherVideos = videos.slice(1);

  return (
    <MainCourses>
      <VideoThumbnail
        poster={mainVideo.thumbnailUrl}
        iconSize={48}
        onClick={() => onPlayVideo(mainVideo.videoUrl)}
      />
      <CardOtherVideoMain>
        <Typography variant="bold/14-20" color={colorsV2.gray120}>
          CÁC VIDEO KHÁC
        </Typography>
        {otherVideos.map((video, idx) => {
          return (
            <OtherVideoMainItem key={idx}>
              <VideoThumbnail
                poster={video.thumbnailUrl}
                iconSize={32}
                onClick={() => onPlayVideo(video.videoUrl)}
              />
              <VideoInfo>
                <Typography
                  className="other-video-title"
                  variant="semi-bold/14-20"
                  color={colorsV2.blackTransparent100}
                >
                  {video.title}
                </Typography>
                <ViewVideo>
                  <ViewOnYoutube
                    href={video.youtubeUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Typography
                      variant="regular/12-16"
                      color={colorsV2.primary100}
                    >
                      Xem trên Youtube
                    </Typography>
                    <IconCourseSocialYoutube
                      width={14}
                      height={14}
                      color={colorsV2.red100}
                    />
                  </ViewOnYoutube>
                </ViewVideo>
              </VideoInfo>
            </OtherVideoMainItem>
          );
        })}
      </CardOtherVideoMain>
    </MainCourses>
  );
};

const OtherCourses = styled.div`
  display: grid;
  gap: 24px;
`;

const CardOtherVideoOther = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  ${toScreen(1143)} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${toScreen(775)} {
    grid-template-columns: repeat(1, 1fr);
  }

  .other-video-main {
    img {
      border-radius: 8px;
    }
  }
`;
const OtherVideoItem = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 113px) auto;
  gap: 16px;
`;

const VideoInfoOther = styled.div`
  display: grid;
  gap: 2px;
  height: fit-content;
  .other-video-title {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .other-video-des {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${colorsV2.neutral180};

  &:hover {
    text-decoration: underline;
  }
`;

const OtherVideoCourses = ({ blogs }) => {
  return (
    <OtherCourses>
      <Typography variant="semi-bold/14-20" color={colorsV2.gray120}>
        Các bài viết khác về Linearthinking
      </Typography>
      <CardOtherVideoOther>
        {blogs.map((item, idx) => (
          <OtherVideoItem key={idx}>
            <LazyImage
              className="other-video-main"
              src={item.page.dol.fileInfo.url}
              alt={item.page.dol.fileInfo.alt}
            />
            <VideoInfoOther>
              <StyledLink to={item.page.url} title={item.page.dol.title}>
                <Typography
                  className="other-video-title"
                  variant="semi-bold/16-24"
                  type="span"
                >
                  {item.page.dol.title}
                </Typography>
              </StyledLink>
              <Typography
                className="other-video-des"
                variant="regular/14-20"
                color={colorsV2.neutral120}
                title={item.page.dol.description}
              >
                {item.page.dol.description}
              </Typography>
            </VideoInfoOther>
          </OtherVideoItem>
        ))}
      </CardOtherVideoOther>
    </OtherCourses>
  );
};

const Main = styled.div`
  display: grid;
  gap: 64px;

  ${toScreen(775)} {
    gap: 48px;
  }

  .video-courses-title {
    text-align: center;
  }
`;

const YoutubeVideoCoursesV3 = ({ blogs }) => {
  const data = useStaticQuery(graphql`
    {
      gcms {
        jsonContanier(where: { name: "Linearthinking_Page_V3" }) {
          content
        }
      }
    }
  `);

  const { videos } = data.gcms.jsonContanier.content;
  const [showModal, setShowModal] = React.useState(false);
  const [videoUrl, setVideoUrl] = React.useState('');

  return (
    <Main className="youtube-video-courses">
      <Typography
        className="video-courses-title"
        variant="bold/24-32"
        desktopVariant="bold/40-48"
        color={colorsV2.black100}
        v3
      >
        Những bài giảng Linearthinking
      </Typography>
      {videos.length > 0 && (
        <MainVideoCourses
          videos={videos}
          onPlayVideo={videoUrl => {
            setVideoUrl(videoUrl);
            setShowModal(true);
          }}
        />
      )}
      <OtherVideoCourses blogs={blogs} />
      <Modal isOpen={showModal} onRequestClose={() => setShowModal(false)}>
        <VideoPlayer videoUrl={videoUrl} autoPlay />
      </Modal>
    </Main>
  );
};

export default YoutubeVideoCoursesV3;
